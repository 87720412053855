// Standard library imports
import React, { useEffect, useState } from 'react';

// External library imports
import { UilTrash, UilPen, UilInfoCircle, UilDocumentInfo } from '@iconscout/react-unicons';
import { toast } from 'react-toastify';
import { Form, Formik, ErrorMessage } from 'formik';
import _ from 'lodash';

// Internal module imports
import Typography from '../../../components/Typography/Typography';
import Table from '../../../components/Table/Table';
import { useLoader } from '../../../hooks';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import { Input, ToggleButton } from '../../../components/Inputs/Input';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import { instanceFormValidation } from '../../../validations/Home/instanceValidation';
import DropdownComponent from '../../../components/Inputs/Dropdown';
import { DropdownComponent as DropDownWithoutFormik } from '../../../components/Inputs/Input';
import { CamelCaseToTextCase } from '../../../utils/stringHelper';
import { TextArea } from '../../../components/Inputs/Input';
import { InstanceService } from '../../../services/instanceService';
import MultiSelectDropdown from '../../../components/Inputs/MultiSelectDropdown';
import { LicenseService } from '../../../services/LicenseService';

import classes from '../../../styles/AllDevices.module.css';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import NewJSONEditor from '../../../components/JSONEditor/NewJSONEditor';
import Info from './ModalComponent/Info';
import DocumentEditor from './ModalComponent/DocumentEditor';
import { COUNTRY_CODES } from '../../../constants';
import instanceClasses from './index.module.css';
import DatePickerWithFormik from '../../../components/Inputs/DatePicker/DatePickerWithFormik';

const countryOption = COUNTRY_CODES.map((country) => {
    return { label: country.name, value: country.name };
});

function Instances({ customer, updateQueryParams, queryParamsData = {} }) {
    const [startLoader, stopLoader] = useLoader();
    const defaultOption = {
        label: 'All',
        value: 'All',
    };
    const [licenseOptions, setLicenseOptions] = useState([defaultOption]);
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const [unfiltered, setUnfiltered] = useState([]);

    const [instanceFormModal, setInstanceFormModal] = useState(
        queryParamsData.instanceFormModal
            ? queryParamsData.instanceFormModal
            : {
                  status: false,
                  data: '',
              }
    );
    const [deleteModal, setDeleteModal] = useState({
        status: false,
        id: '',
    });
    const [licenseTypes, setLicenseTypes] = useState([]);
    const [markets, setMarkets] = useState([]);
    const [instances, setInstances] = useState([]);
    const [infoModal, setInfoModal] = useState({
        status: false,
        data: {},
    });
    const [isOpenDocumentModal, setOpenDocumentModal] = useState({
        status: false,
        data: {},
    });

    const initialValues = {
        name: instanceFormModal.data?.name ? instanceFormModal.data.name : '',
        description: instanceFormModal.data?.description ? instanceFormModal.data.description : '',
        licenseId: instanceFormModal.data?.licenseId?._id ? instanceFormModal.data.licenseId._id : '',
        market: instanceFormModal.data?.market
            ? instanceFormModal.data.market.map((item) => ({
                  label: item,
                  value: item,
              }))
            : [],
        meta: instanceFormModal.data?.meta ? instanceFormModal.data.meta : {},
        metaErrorKeys: {},
        inMarket: instanceFormModal.data?.inMarket || instanceFormModal.data?.inMarket === false ? instanceFormModal.data.inMarket : true,
        inLedger: _.get(instanceFormModal, ['data', 'inLedger'], false),
        approvedCapacity: instanceFormModal.data?.approvedCapacity || {},
        webshopUrl: instanceFormModal.data?.webshopUrl || '',
        jiraLink: instanceFormModal.data?.jiraLink || '',
        address: instanceFormModal.data?.address || {
            floor: null,
            city: null,
            pincode: null,
            streetName: null,
            country: _.get(countryOption, [0, 'value'], null),
        },
        approvalValidity: instanceFormModal?.data?.approvalValidity || {},
    };
    const [defaultMetaKeys, setDefaultMetaKeys] = useState({});
    const [updateDefaultValueEditor, setUpdateDefaultValueEditor] = useState(false);

    useEffect(() => {
        searchFilter(unfiltered);
    }, [selectedOption]);

    useEffect(() => {
        const markets = [];
        if (customer.biddingMarkets?.length > 0) {
            customer.biddingMarkets.forEach((market) => markets.push({ label: market, value: market }));
        }

        markets.length > 0 ? setMarkets(markets) : setMarkets([]);
    }, [customer.biddingMarkets]);

    useEffect(() => {
        getAllInstances();
        getLicenseTypes();
    }, []);

    const searchFilter = (data) => {
        if (selectedOption.value === 'All') {
            setInstances(data);
        } else {
            const filtered = data.filter((instance) => instance.licenseType === selectedOption.value).map((instance, index) => ({ ...instance, 's.no': index + 1 }));
            setInstances(filtered);
        }
    };

    const getLicenseTypes = () => {
        LicenseService.ReadV2(customer._id, startLoader, handleLicenseTypesSuccess, handleError, stopLoader);
    };

    const handleLicenseTypesSuccess = ({ data }) => {
        const licenseTypes = [];
        data.data?.forEach((license) => {
            licenseTypes.push({
                label: CamelCaseToTextCase(license.name),
                value: license._id,
                metaKeys: license?.defaultMetaKeys || [],
            });
        });
        setLicenseTypes(licenseTypes);
    };

    const handleInstanceSuccess = ({ data }) => {
        const licenseTypes = new Set([]);
        const tableData = data?.data.map((item, index) => {
            licenseTypes.add(CamelCaseToTextCase(item.licenseId.name));
            return {
                's.no': index + 1,
                name: item?.name,
                instanceId: item._id,
                description: !item?.description ? (
                    '--'
                ) : item.description.length > 40 ? (
                    <CustomTooltip content={item.description}>{item.description.substring(0, 40) + '...'}</CustomTooltip>
                ) : (
                    item.description
                ),
                licenseType: CamelCaseToTextCase(item.licenseId.name),
                market: item?.market.length ? item?.market.join(', ') : '--',
                monitoring: item?.monitoring,
                active: item?.active,
                action: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div>
                            <CustomTooltip content={'Info'}>
                                <UilInfoCircle size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setInfoModal({ status: true, data: item })} />
                            </CustomTooltip>
                        </div>
                        <div style={{ marginLeft: '0.3vw' }}>
                            <CustomTooltip content={'Document'}>
                                <UilDocumentInfo
                                    size={'1.2vw'}
                                    style={{ color: 'var(--color-primary)' }}
                                    onClick={() => {
                                        setOpenDocumentModal({ status: true, data: item });
                                    }}
                                />
                            </CustomTooltip>
                        </div>
                        <div style={{ marginLeft: '0.3vw' }}>
                            <CustomTooltip content={'Edit'}>
                                <UilPen
                                    size={'1.2vw'}
                                    style={{ color: 'var(--color-primary)' }}
                                    onClick={() => {
                                        setInstanceFormModal({
                                            status: true,
                                            data: item,
                                        });
                                    }}
                                />
                            </CustomTooltip>
                        </div>

                        <div style={{ marginLeft: '0.3vw' }}>
                            <CustomTooltip content={'Delete'}>
                                <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setDeleteModal({ status: true, id: item._id })} />
                            </CustomTooltip>
                        </div>
                    </div>
                ),
            };
        });
        searchFilter(tableData);
        const licenseDropOptions = [defaultOption];
        licenseTypes.forEach((type) => {
            licenseDropOptions.push({ label: type, value: type });
        });
        setLicenseOptions(licenseDropOptions);
        setUnfiltered(tableData);
    };

    const getAllInstances = () => {
        InstanceService.ReadAll(customer._id, startLoader, handleInstanceSuccess, handleError, stopLoader);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handleSuccess = (res) => {
        if (res) {
            toast.success(res);
            getAllInstances();
            setInstanceFormModal({ status: false, data: '' });
        }
    };

    const handleFormSubmit = (data) => {
        const bodyData = { customerId: customer._id, ...data };
        bodyData.market = data.market.map((item) => item.value);
        if (!instanceFormModal.data) {
            InstanceService.Create(bodyData, startLoader, () => handleSuccess('Instance Created Successfully'), handleError, stopLoader);
        } else {
            InstanceService.Update(instanceFormModal.data._id, bodyData, startLoader, () => handleSuccess('Instance Updated Successfully'), handleError, stopLoader);
        }
    };

    const handleDelete = () => {
        InstanceService.Delete(deleteModal.id, startLoader, () => handleSuccess('Instance Deleted Successfully'), handleError, stopLoader);
    };

    return (
        <div className={classes.AllDevices}>
            <DeleteModal
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
            ></DeleteModal>
            <ModalComponent
                isOpen={infoModal.status}
                setOpen={() => {
                    setInfoModal({ status: false, data: {} });
                }}
            >
                <div>
                    <Info data={infoModal.data} setInfoModal={setInfoModal} />
                </div>
            </ModalComponent>
            <ModalComponent isOpen={isOpenDocumentModal.status} setOpen={() => {}}>
                <div style={{ overflow: 'auto' }}>
                    <DocumentEditor instance={isOpenDocumentModal.data} setOpen={setOpenDocumentModal} />
                </div>
            </ModalComponent>
            <ModalComponent
                isOpen={instanceFormModal.status}
                setOpen={(e) => {
                    setInstanceFormModal({ status: false, data: '' });
                }}
                style={{ overflow: 'initial' }}
            >
                <Typography content={instanceFormModal.data ? 'Edit Instance' : 'Create Instance'} />
                <div style={{ minWidth: '30vw', height: '80vh', overflow: 'auto' }}>
                    <Formik initialValues={initialValues} validationSchema={instanceFormValidation(defaultMetaKeys)} onSubmit={handleFormSubmit} enableReinitialize>
                        {({ errors, touched, values, isValidating, ...props }) => {
                            return (
                                <Form>
                                    <div className={classes.FieldControl2}>
                                        <label for="name">
                                            Name <span className="required">*</span>
                                        </label>
                                        <Input
                                            name="name"
                                            id="name"
                                            type="text"
                                            style={{
                                                marginTop: '0',
                                                padding: '0.1vw 0 0.1vw 0.5vw',
                                            }}
                                        />
                                    </div>
                                    <div className={classes.FieldControl2}>
                                        <label for="description">Description</label>
                                        <TextArea name="description" type="text" />
                                    </div>
                                    <div className={classes.FieldControl2}>
                                        <label for="licenseId">
                                            Licenses Type<span className="required">*</span>
                                        </label>
                                        <DropdownComponent
                                            name="licenseId"
                                            id="licenseId"
                                            options={licenseTypes}
                                            defaultValue={{ value: initialValues.licenseId }}
                                            onChange={(e) => {
                                                setUpdateDefaultValueEditor(true);
                                                let temp = {};
                                                e?.metaKeys?.map((key) => {
                                                    temp[key] = initialValues?.meta[key] || '';
                                                });
                                                props.setFieldValue('meta', {
                                                    ...temp,
                                                    ...initialValues?.meta,
                                                });
                                                props.setFieldValue('metaErrorKeys', temp);
                                                setDefaultMetaKeys(temp);
                                            }}
                                        />
                                    </div>
                                    <div className={classes.FieldControl2}>
                                        <label for="market">
                                            Market<span className="required">*</span>
                                        </label>
                                        <MultiSelectDropdown name="market" options={markets} defaultValue={initialValues?.market} />
                                    </div>

                                    <div>
                                        {values.market.length ? (
                                            <div className={classes.FieldControl2}>
                                                <label for="approval">Approval Validity</label>
                                            </div>
                                        ) : (
                                            ''
                                        )}

                                        {values.market.map((item) => (
                                            <div className={classes.InputContainer}>
                                                <div className={classes.FieldControl2}>
                                                    <label for="startDate">Start Date ({item.value})</label>
                                                    <div className="modal-date-picker">
                                                        <DatePickerWithFormik
                                                            name={`approvalValidity.${item.value}.startDate`}
                                                            date={_.get(values, ['approvalValidity', item.value, 'startDate'], null)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={classes.FieldControl2}>
                                                    <label for="endDate">End Date ({item.value})</label>
                                                    <div className="modal-date-picker">
                                                        <DatePickerWithFormik
                                                            name={`approvalValidity.${item.value}.endDate`}
                                                            date={_.get(values, ['approvalValidity', item.value, 'endDate'], null)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className={classes.InputContainer}>
                                        <div className={classes.FieldControl2}>
                                            <label for="meta">Meta</label>
                                            <NewJSONEditor
                                                name="meta"
                                                defaultValue={values.meta}
                                                manualDefaultValueUpdate={updateDefaultValueEditor}
                                                setManualDefaultValueUpdate={setUpdateDefaultValueEditor}
                                                height="10rem"
                                                onChange={(e) => {
                                                    props.setFieldValue('metaErrorKeys', e);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        {_.values(errors.metaErrorKeys).map((e) => (
                                            <div>
                                                <div className="error-msg">{e?.replace('metaErrorKeys', 'meta')}</div>
                                            </div>
                                        ))}
                                    </div>

                                    <div>
                                        {values.market.length ? (
                                            <div className={classes.FieldControl2}>
                                                <label for="approval">Approved Capacity (MW)</label>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        <div className={instanceClasses.ApprovedCapacityContainer}>
                                            {values.market.map((item) => (
                                                <div className={classes.FieldControl2}>
                                                    <label>{item.value}</label>
                                                    <Input
                                                        name={`approvedCapacity.${item.value}`}
                                                        id={`approvedCapacity.${item.value}`}
                                                        type="number"
                                                        style={{
                                                            marginTop: '0',
                                                            padding: '0.1vw 0 0.1vw 0.5vw',
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className={classes.FieldControl2}>
                                        <label>Webshop URL</label>
                                        <Input
                                            name="webshopUrl"
                                            id="webshopUrl"
                                            type="text"
                                            style={{
                                                marginTop: '0',
                                                padding: '0.1vw 0 0.1vw 0.5vw',
                                            }}
                                        />
                                    </div>
                                    <div className={classes.FieldControl2}>
                                        <label>Jira Link</label>
                                        <Input
                                            name="jiraLink"
                                            id="jiraLink"
                                            type="text"
                                            style={{
                                                marginTop: '0',
                                                padding: '0.1vw 0 0.1vw 0.5vw',
                                            }}
                                        />
                                    </div>
                                    <div className={classes.FieldControl2}>
                                        <label for="approval">Address</label>
                                    </div>
                                    <div className={classes.InputContainer}>
                                        <div className={classes.FieldControl2}>
                                            <label>Floor</label>
                                            <Input
                                                name="address.floor"
                                                id="floor"
                                                type="text"
                                                style={{
                                                    marginTop: '0',
                                                    padding: '0.1vw 0 0.1vw 0.5vw',
                                                }}
                                            />
                                        </div>
                                        <div className={classes.FieldControl2}>
                                            <label>Street Name</label>
                                            <Input
                                                name="address.streetName"
                                                id="streetName"
                                                type="text"
                                                style={{
                                                    marginTop: '0',
                                                    padding: '0.1vw 0 0.1vw 0.5vw',
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.InputContainer}>
                                        <div className={classes.FieldControl2}>
                                            <label>City</label>
                                            <Input
                                                name="address.city"
                                                id="city"
                                                type="text"
                                                style={{
                                                    marginTop: '0',
                                                    padding: '0.1vw 0 0.1vw 0.5vw',
                                                }}
                                            />
                                        </div>
                                        <div className={classes.FieldControl2}>
                                            <label>Pincode</label>
                                            <Input
                                                name="address.pincode"
                                                id="pincode"
                                                type="number"
                                                style={{
                                                    marginTop: '0',
                                                    padding: '0.1vw 0 0.1vw 0.5vw',
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.InputContainer}>
                                        <div className={classes.FieldControl2}>
                                            <label>Country</label>
                                            <DropdownComponent
                                                name="address.country"
                                                id="licenseId"
                                                options={countryOption}
                                                defaultValue={{ value: initialValues?.address?.country }}
                                            />
                                        </div>
                                        <div className={classes.FieldControl2}></div>
                                    </div>

                                    <div className={classes.InputContainer}>
                                        <ToggleButton values={values} label={'In market'} name={`inMarket`} />
                                        <ToggleButton values={values} label={'In Ledger'} name={`inLedger`} />
                                    </div>
                                    <div className={classes.ButtonContainer}>
                                        <div>
                                            <button type="submit" className="btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </ModalComponent>
            <div className={classes.Header}>
                <div>
                    <Typography content="Instances" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={instances?.length || 0} />
                        </span>
                    </div>
                    <div className={classes.FieldControl} style={{ maxWidth: '15vw' }}>
                        <DropDownWithoutFormik name="license" options={licenseOptions} onChange={setSelectedOption} defaultValue={licenseOptions[0]} />
                    </div>
                </div>
                <div>
                    <button
                        type="submit"
                        className="btn-primary"
                        style={{ marginTop: '0' }}
                        onClick={() =>
                            setInstanceFormModal({
                                status: true,
                                data: '',
                            })
                        }
                    >
                        Create Instance
                    </button>
                </div>
            </div>
            <div>
                <Table
                    head={['S.No', 'Name', 'Instance Id', 'Description', 'License Type', 'Market', 'Action']}
                    keys={['s.no', 'name', 'instanceId', 'description', 'licenseType', 'market', 'action']}
                    data={instances}
                />
            </div>
        </div>
    );
}

export default Instances;
