// External library import
import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';

export const EdgeDevice = yup.object().shape({
    edgeAlias: yup.string().required(),
    systemId: yup.string().required(),
    disabled: yup.boolean().required(),
    licenseId: yup.string().optional(),
    groupId: yup.string().when('licenseId', {
        is: undefined,
        then: yup.string().optional(),
        otherwise: yup.string().required(),
    }),
    market: yup.string().when('groupId', {
        is: undefined,
        then: yup.string().optional(),
        otherwise: yup.string().required(),
    }),
    properties: yup.object().shape({
        signalId: yup.string().test('SignalId', ERRORS.required, (value, c) => {
            let isGroupId = c?.from?.[1]?.value?.groupId;
            if (isGroupId) {
                if (value !== undefined) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        }),
        frequency: yup.object().optional(),
        edgeConfiguration: yup.mixed().optional(),
        profile: yup
            .object()
            .shape({
                basePower: yup.boolean().optional(),
            })
            .optional(),
        maxDuration: yup.number().required(ERRORS.required),
        startDelay: yup.number().required(ERRORS.required),
        codeVersion: yup.string().optional(),
        notes: yup.string().optional(),
    }),
});
