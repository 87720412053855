// Standard library imports
import React, { useState, useEffect } from 'react';

// Internal module imports
import { DropdownComponent } from '../../../../components/Inputs/Input';
import { CreditsServiceRoot } from '../../../../services/RootCreditsService';
import { useLoader } from '../../../../hooks';
import Typography from '../../../../components/Typography/Typography';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';

// Css imports
import classes from '../../../../styles/AllDevices.module.css';
import invoiceClasses from './index.module.css';

// External library imports
import { toast } from 'react-toastify';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import InvoicePreview from './modalComponents/InvoicePreview';
import Table from '../../../../components/Table/Table';
import { UilInfoCircle } from '@iconscout/react-unicons';
import moment from 'moment/moment';
import MonthPicker from '../../../../components/Inputs/MonthPicker/MonthPicker';
import { InvoiceServiceRoot } from '../../../../services/RootInvoiceService';
import CkeEditor from '../../../../components/CkeEditor';
import { Form, Formik } from 'formik';
import { momentTimeFormater } from '../../../../utils/timeHelper';

const ACCOUNT_TYPE = [
    {
        label: 'Partners',
        value: 'admin',
    },
    {
        label: 'Enablers',
        value: 'enabler',
    },
    {
        label: 'Customers',
        value: 'customer',
    },
];

const LIMIT = 15;

const Invoice = ({ queryParamsData, updateQueryParams }) => {
    const [startLoader, stopLoader] = useLoader();
    const [date, setDate] = useState(
        queryParamsData.date
            ? new Date(queryParamsData.date)
            : new Date()
    );
    const [accountOptions, setAccountOptions] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState({});
    const [selectedAccountType, setSelectedAccountType] = useState({});
    const [open, setOpen] = useState(false);
    const [invoiceData, setInvoiceData] = useState([]);
    const [revenue, setRevenue] = useState(0);
    const [revenueEuro, setRevenueEuro] = useState(0);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [infoModal, setInfoModal] = useState({ status: false, data: '' });

    useEffect(() => {
        updateQueryParams('date', date);
    }, [date]);

    useEffect(() => {
        if (selectedAccountType?.value) {
            getAccountsData();
        }
    }, [selectedAccountType]);

    useEffect(() => {
        if (selectedAccount?.value) {
            GetRevenue();
        }
    }, [selectedAccount, date]);

    useEffect(() => {
        getInvoices();
    }, [skip]);

    useEffect(() => {
        setSelectedAccount(accountOptions[0]);
    }, [JSON.stringify(accountOptions)])

    const getAccountsData = () => {
        CreditsServiceRoot.GetAccounts({ type: selectedAccountType.value }, startLoader, handleGetAccountsSuccess, handleError, stopLoader);
    };

    const handleGetAccountsSuccess = ({ data }) => {
        const temp = data.data.map((item) => ({
            label: item.name,
            value: item._id,
        }));
        setAccountOptions(temp);
    };

    const getInvoices = () => {
        InvoiceServiceRoot.getInvoices(
            {
                limit: LIMIT,
                skip: skip,
            },
            startLoader,
            handleGetInvoicesSuccess,
            handleError,
            stopLoader
        );
    };

    const handleGetInvoicesSuccess = ({ data }) => {

        const processedData = data.data?.data.map((item) => {
            return {
                month: moment(item.date).format('MMMM'),
                accountType: item.invoiceType,
                sentAt: momentTimeFormater(item.createdAt).format('YYYY-MM-DD'),
                emails: (<CustomTooltip content={item.emails.join(", ")}>
                    <div>{item.emails.join(", ").substring(0, 40) + '...'}</div>
                </CustomTooltip>),
                preview: (
                    <CustomTooltip content={'preview'}>
                        <UilInfoCircle
                            style={{
                                color: 'var(--color-primary)',
                            }}
                            size={'1.3vw'}
                            onClick={() => setInfoModal({ status: true, data: item.emailBody })}
                        />
                    </CustomTooltip>
                )
            }
        });

        setInvoiceData(processedData);
        setTotalCount(data.data?.count ? data.data.count : 0);
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    const GetRevenue = () => {
        InvoiceServiceRoot.GetRevenue(
            {
                type: selectedAccountType.value,
                id: selectedAccount.value,
                date: moment(date).format('YYYY-MM-DD'),
            },
            startLoader,
            handleGetRevenueSuccess,
            handleError,
            stopLoader
        );
    };

    const handleGetRevenueSuccess = ({ data }) => {
        setRevenue(data?.data?.revenueDKK.toFixed(3));
        setRevenueEuro(data?.data?.revenueEuro.toFixed(3));
    };

    const handleError = (err) => {
        toast.error(err?.response?.data?.message);
    };

    return (
        <div>
            <ModalComponent isOpen={open} setOpen={setOpen}>
                <div style={{ maxWidth: "80vw", maxHeight: '90vh' }}>
                    <InvoicePreview
                        accountType={selectedAccountType?.value}
                        accountTypeValue={selectedAccount?.label}
                        accountTypeId={selectedAccount?.value}
                        date={date}
                        revenue={revenue}
                        revenueEuro={revenueEuro}
                        setOpen={setOpen}
                        getInvoices={getInvoices}
                    />
                </div>
            </ModalComponent>
            <ModalComponent isOpen={infoModal.status} setOpen={() => setInfoModal({ status: false, data: '' })}>
                <div style={{ maxWidth: "80vw", maxHeight: '90vh', paddingBottom: '1vw' }}>
                    <Typography content={'Invoice Preview'} />

                    <Formik initialValues={{ emailBody: infoModal.data }}>
                        {() => {
                            return (
                                <Form>
                                    <div className={classes.FieldControl2}>
                                        <label for="emailBody">
                                            Email Body
                                        </label>
                                        <CkeEditor
                                            name="emailBody"
                                            defaultValue={infoModal.data}
                                            // apiUrl={'root/economics/invoice'}
                                            disabled={true}
                                        ></CkeEditor>
                                        <div className={classes.ButtonContainer}>
                                            <div>
                                                <button type="button" className="btn-secondary" onClick={() => setInfoModal({ status: false, data: '' })}>
                                                    Close
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </ModalComponent>
            <div className={invoiceClasses.Filter}  >
                <div className={classes.Header}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className={invoiceClasses.FilterContainer}>
                            <div>
                                <div className={classes.FieldControl}>
                                    <label>Account Type</label>
                                    <DropdownComponent
                                        name="accountType"
                                        options={ACCOUNT_TYPE}
                                        defaultValue={queryParamsData.accountType ? JSON.parse(queryParamsData.accountType) : ACCOUNT_TYPE[0]}
                                        onChange={(e) => {
                                            setSelectedAccountType(e);
                                            updateQueryParams('accountType', JSON.stringify(e));
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className={classes.FieldControl}>
                                    <label>Value</label>
                                    <DropdownComponent
                                        name="accountId"
                                        options={accountOptions}
                                        defaultValue={queryParamsData.account ? JSON.parse(queryParamsData.account) : accountOptions[0]}
                                        onChange={(e) => {
                                            setSelectedAccount(e);
                                            updateQueryParams('account', JSON.stringify(e));
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={invoiceClasses.DatePickers}>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className={invoiceClasses.DateLabels}>Month</div>
                                </div>
                                <MonthPicker date={date} setDate={(e) => setDate(e)} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={invoiceClasses.Header}>
                    <div>
                        <Typography content="Revenue" />
                        <div className={classes.TableCount}>
                            {selectedAccountType?.label} :
                            <span>
                                <Typography size="14" content={revenue ? ` ${revenue} DKK` : ` 0 DKK`} />
                            </span>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="btn-primary"
                                style={{ marginTop: '0' }}
                                onClick={() => {
                                    setOpen(true);
                                }}
                            >
                                Send Invoice
                            </button>
                        </div>
                    </div>

                </div>
            </div>

            <div className={classes.AllDevices}>
                <div className={classes.Header}>
                    <div>
                        <Typography content="Invoices Sent History" />
                    </div>
                </div>
                <div>
                    <Table
                        head={['Month', 'Account Type', 'Emails', 'Sent At', 'Preview']}
                        keys={['month', 'accountType', 'emails', 'sentAt', 'preview']}
                        data={invoiceData}
                        page={page}
                        Pagination={true}
                        limit={LIMIT}
                        handlePageChange={handlePageChange}
                        totalCount={totalCount}
                    />
                </div>
            </div>
        </div>
    );
};

export default Invoice;
