import React, { useEffect, useState } from 'react';
import classes from '../../../../styles/AllDevices.module.css';
import Typography from '../../../../components/Typography/Typography';
import { toast } from 'react-toastify';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import { Input } from '../../../../components/Inputs/Input';
import { Form, Formik } from 'formik';
import businessWebsiteClasses from '../Content.module.css';
import { RootInNewsService } from '../../../../services/RootInNewsService';
import { useLoader } from '../../../../hooks';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import { UilTrash } from '@iconscout/react-unicons';
import { getLinkPreview } from 'link-preview-js';
import { inNewsValidation } from '../../../../validations/Root/inNewsvalidation';

const InNews = () => {
    const [data, setData] = useState([]);
    const [addImageModal, setImageModal] = useState(false);
    const [previewLink, setPreviewLink] = useState(null);
    const [startLoader, stopLoader] = useLoader();
    const [initialValues, setInitialValues] = useState({
        title: '',
        url: '',
    });
    const [deleteModal, setDeleteModal] = useState({
        status: false,
        imageId: '',
    });

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        RootInNewsService.ReadAll(startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = async (res) => {
        const processed = await Promise.all(
            res.data.data.map(async (item) => {
                try {
                    const response = await getLinkPreview(item.url);
                    return {
                        _id: item._id,
                        prev: response.images?.[0],
                        link: item.link,
                        title: item.title,
                    };
                } catch (error) {
                    console.error(`Error fetching preview for ${item.link}`, error);
                    return null;
                }
            })
        );

        setData(processed.filter((item) => item !== null));
    };

    const handleSubmit = (values) => {
        if (previewLink) {
            RootInNewsService.Create(values, startLoader, handleCreateSuccess, handleError, stopLoader);
        } else {
            toast.error('No preview available');
        }
    };

    const handleCreateSuccess = ({ data }) => {
        toast.success('News added!');
        getData();
        setImageModal(false);
        setPreviewLink(null);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handleDelete = () => {
        const data = {
            id: deleteModal.imageId,
        };
        RootInNewsService.Delete(data, startLoader, handleDeleteSuccess, handleError, stopLoader);
    };

    const handleDeleteSuccess = (res) => {
        if (res) {
            toast.success('News deleted');
            setDeleteModal({ status: false, imageId: '' });
            getData();
        }
    };

    const linkChangeHandler = async (e) => {
        try {
            const link = e.target.value;
            if (link && /^(https?:\/\/)?([\w\-]+(\.[\w\-]+)+)(:\d+)?(\/[^\s]*)?$/.test(link)) {
                const preview = await getLinkPreview(link);
                setPreviewLink(preview.images?.[0]);
            }
        } catch (error) {
            setPreviewLink(null);
        }
    };

    return (
        <div className={classes.AllDevices}>
            <DeleteModal
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
            ></DeleteModal>
            <ModalComponent
                isOpen={addImageModal}
                setOpen={(status) => {
                    setImageModal(status);
                    setPreviewLink(null);
                }}
                style={{ overflow: 'initial' }}
            >
                <div style={{ height: 'auto', width: '20vw' }}>
                    <div className={businessWebsiteClasses.ModalWrapper}>
                        <Typography content={'Add News'} />
                    </div>
                    <div className={classes.FormContent}>
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize
                            validationSchema={inNewsValidation}
                            onSubmit={(val, { resetForm }) => handleSubmit(val, resetForm)}
                        >
                            {({ errors, touched, values, isValidating, ...props }) => {
                                return (
                                    <Form>
                                        <div className={businessWebsiteClasses.InputContainer}>
                                            <div className={businessWebsiteClasses.FieldControl2}>
                                                <label for="name">
                                                    Title
                                                    <span className="required">*</span>
                                                </label>
                                                <div>
                                                    <Input name="title" id="title" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={businessWebsiteClasses.InputContainer}>
                                            <div className={businessWebsiteClasses.FieldControl2}>
                                                <label for="name">
                                                    URL
                                                    <span className="required">*</span>
                                                </label>
                                                <div>
                                                    <Input name="url" onKeyUp={linkChangeHandler} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={businessWebsiteClasses.InputContainer}>
                                            <div className={businessWebsiteClasses.FieldControl2}>
                                                <label for="name">Link Preview</label>
                                            </div>
                                            {previewLink && <img src={previewLink} alt="preview" style={{ maxHeight: '300px', maxWidth: '300px' }} />}
                                        </div>
                                        <div>
                                            <div style={{ display: 'flex', gap: '0.4vw' }}>
                                                <button
                                                    className="btn-secondary"
                                                    onClick={() => {
                                                        setImageModal(false);
                                                        setInitialValues(initialValues);
                                                        setPreviewLink(null);
                                                    }}
                                                    type="button"
                                                >
                                                    Cancel
                                                </button>
                                                <button type="submit" className="btn-primary">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </ModalComponent>
            <div className={classes.Header}>
                <div>
                    <Typography content="In News" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={data.length} />
                        </span>
                    </div>
                    <div>
                        <button type="submit" className="btn-primary" style={{ marginTop: '0' }} onClick={(e) => setImageModal(true)}>
                            Add News
                        </button>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: '2vw' }}>
                <div className={businessWebsiteClasses.ImageContainer}>
                    {data.map((item) => (
                        <div className={businessWebsiteClasses.MainDiv} style={{ height: 'auto', overflowWrap: 'break-word' }}>
                            <div>
                                <div
                                    style={{
                                        backgroundImage: `url(${item.prev})`,
                                        height: '200px',
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                    }}
                                >
                                    <div className={businessWebsiteClasses.NameWrapper}>
                                        <div></div>
                                        <div className={businessWebsiteClasses.IconWrapper}>
                                            <CustomTooltip content={'Delete News'}>
                                                <div onClick={() => setDeleteModal({ status: true, imageId: item._id })}>
                                                    <UilTrash
                                                        size={'1.2vw'}
                                                        style={{
                                                            color: 'var(--color-primary)',
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </div>
                                            </CustomTooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    fontSize: '0.8vw',
                                    color: 'var(--color-primary)',
                                    padding: '5px',
                                }}
                            >
                                {item.title}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default InNews;
