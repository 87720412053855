// Standard library imports
import React, { useEffect, useState } from 'react';

// External library imports
import moment from 'moment';
import { toast } from 'react-toastify';
import { UilImport, UilInfoCircle } from '@iconscout/react-unicons';
import { flatten } from 'flat';
import { Menu, MenuItem, SubMenu } from '@szhsin/react-menu';

// Internal module imports
import Typography from '../../../../components/Typography/Typography';
import AddProfile from './AddProfile';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import { useLoader } from '../../../../hooks';
import ChartComponent from '../../../../components/ApexCharts/Chart';
import DatePicker from '../../../../components/Inputs/DatePicker/DatePicker';
import CustomTimePicker from '../../../../components/Inputs/TimePicker/TimePicker';
import { timeOnlyWithms } from '../../../../utils/dateHelper';
import { usToEuCurrencyFormat } from '../../../../utils/currencyHelper';
import { signalsServiceForRoot } from '../../../../services/signalsServiceForRoot';
import { DownloadAsExcel, DownloadAsExcelWithBuffer } from '../../../../utils/downloadAsExcel';
import { truncateNumber } from '../../../../utils/numberHelper';
import { DropdownComponent, ToggleButtonWithState } from '../../../../components/Inputs/Input';
import { RootActivationProfileService, getEnerginetFormatData } from '../../../../services/RootActivationProfileService';
import { getDuration, momentTimeFormater } from '../../../../utils/timeHelper';
import { CamelCaseToTextCase } from '../../../../utils/stringHelper';
import TabsComponent from '../../../../components/Tabs/Tabs';
import Summary from './Summary';
import Events from './Events';
import IntMeta from './intMeta';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import PQDownload from '../ModalComponent/PQDownload';

// Css imports
import classes from '../../../../styles/Overview.module.css';
import activationClasses from './ActivationProfiler.module.css';
import ThreeStateToggle from '../../../../components/Inputs/ThreeStateToggle/ThreeStateToggle';
import { constructEnerginetFrequency } from '../../../../utils/energinetFrequency';

const CAPACITY_OPTIONS = {
    left: {
        title: 'Bid Capacity',
        value: 'left',
    },
    right: {
        title: 'Edge Capacity',
        value: 'right',
    },
};

const ActivationProfiler = ({ queryParamsData, updateQueryParams }) => {
    const [lastStatus, setLastStatus] = useState('--');
    const [profile, setProfile] = useState({});
    const [editProfile, setEditProfile] = useState({});
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [graphData, setGraphData] = useState([]);
    const [powerData, setPowerData] = useState([]);
    const [startLoader, stopLoader] = useLoader();
    const [seconds, setSeconds] = useState(30);
    const [counterInterval, setCounterInterval] = useState(null);
    const [isAutoRefresh, setIsAutorefresh] = useState(false);
    const [disableAutoRefresh, setDisableAutoResh] = useState(false);
    const [activationLogs, setActivationLogs] = useState([]);
    const curCetDateTime = moment().tz('Europe/Berlin');
    const [date, setDate] = useState(queryParamsData.date ? queryParamsData.date : new Date(curCetDateTime.format()));
    const [endTime, setEndTime] = useState(queryParamsData.endTime ? queryParamsData.endTime : curCetDateTime.format('HH:mm:ss'));
    const [startTime, setStartTime] = useState(queryParamsData.startTime ? queryParamsData.startTime : curCetDateTime.subtract(15, 'minutes').format('HH:mm:ss'));
    const [frequencyAnnotations, setFrequencyAnnotations] = useState(null);
    const [yaxisVisible, setYaxisVisible] = useState({});
    const [isError, setIsError] = useState(false);
    const [summarySubmitEvent, setSummarySubmitEvent] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.subTab ? queryParamsData.subTab : 0);
    const [PQModal, setPQModal] = useState(false);

    const [customers, setCustomers] = useState([
        {
            label: 'All',
            value: 'all',
        },
    ]);
    const [profiles, setProfiles] = useState([
        {
            label: 'All',
            value: 'all',
        },
    ]);
    const [admins, setAdmins] = useState([
        {
            label: 'All',
            value: 'all',
        },
    ]);
    const [selectedAdmin, setSelectedAdmin] = useState(
        queryParamsData.admin
            ? JSON.parse(queryParamsData.admin)
            : {
                  label: 'All',
                  value: 'all',
              }
    );
    const [selectedCustomer, setSelectedCustomer] = useState(
        queryParamsData.customer
            ? JSON.parse(queryParamsData.customer)
            : {
                  label: 'All',
                  value: 'all',
              }
    );
    const [rawData, setRawData] = useState({});
    const [intMeta, setIntMeta] = useState([]);
    const [edgeCapacity, setEdgeCapacity] = useState(true);

    const frequencyMarketProperties = {
        fcr: {
            lineMin: 49.98,
            lineMax: 50.02,
            lowVisible: 49.8,
            highVisible: 50.2,
        },
        fcrD: {
            lineMin: 50.1,
            lowVisible: 49.8,
            highVisible: 50.7,
        },
    };

    useEffect(() => {
        updateQueryParams('date', date);
    }, [date]);

    const formatYAxisValue = (value) => {
        return usToEuCurrencyFormat(value, 3);
    };

    useEffect(() => {
        if (profile?.signalId && selectedIndex === 0) {
            getData();
            getLastReported();
        }
    }, [selectedIndex, profile, edgeCapacity]);

    const getData = (activationStartTime = null, activationEndTime = null) => {
        const params = {
            start: activationStartTime ? activationStartTime : startTime,
            end: activationEndTime ? activationEndTime : endTime,
            date: new Date(moment(date).tz('Europe/Berlin').format()),
            edgeId: profile?.value,
            prefillData: false,
            dataDecimation: true,
            isEdgeCapacity: edgeCapacity,
        };
        signalsServiceForRoot.getActivationProfile(params, startLoader, handleSuccess, handleError, stopLoader);
        getActivationLog();
    };

    const handleSuccess = ({ data }) => {
        if (data?.data?.message) {
            setIsError(true);
            toast.error(data?.data?.message);
        } else {
            setIsError(false);
            setGraphData(data.data?.frequency);
            setPowerData(data.data?.power);
        }
    };

    const getLastReported = () => {
        if (profile?.signalId) {
            signalsServiceForRoot.getLastReportedData({ signalId: profile?.signalId }, startLoader, handleLastReportedSuccess, handleError, stopLoader);
        }
    };

    const handleLastReportedSuccess = ({ data }) => {
        if (data.data) {
            setLastStatus(moment(data?.data).tz('Europe/Berlin').format('YYYY-MM-DD HH:mm:ss'));
        } else {
            setLastStatus('--');
        }
    };

    const getActivationLog = () => {
        const params = {
            id: profile.value,
            date: date,
        };

        RootActivationProfileService.GetActivation(params, startLoader, handleActivationLogSuccess, handleError, stopLoader);
    };

    const handleActivationLogSuccess = ({ data }) => {
        setActivationLogs(data?.data || []);
    };

    const handleError = (err) => {
        if (err) {
            toast.error(err?.response?.data?.message);
        }
    };
    const handleMarketChange = (value) => {
        if (value === 'FCR') {
            setYaxisVisible({
                lowVisible: frequencyMarketProperties.fcr.lowVisible,
                highVisible: frequencyMarketProperties.fcr.highVisible,
            });
            setFrequencyAnnotations({
                yaxis: [
                    {
                        y: frequencyMarketProperties.fcr.lineMin,
                        borderColor: '#219e4d',
                        label: {
                            borderColor: '#219e4d',
                            style: {
                                color: '#fff',
                                background: '#219e4d',
                                fontSize: '0.8vw',
                            },
                            text: String(frequencyMarketProperties.fcr.lineMin),
                        },
                    },
                    {
                        y: frequencyMarketProperties.fcr.lineMax,
                        borderColor: '#775DD0',
                        label: {
                            borderColor: '#775DD0',
                            style: {
                                color: '#fff',
                                background: '#775DD0',
                                fontSize: '0.8vw',
                            },
                            text: String(frequencyMarketProperties.fcr.lineMax),
                        },
                    },
                ],
            });
        } else if (value === 'FCR-D-INC' || value === 'FCR-D') {
            setYaxisVisible({
                lowVisible: frequencyMarketProperties.fcrD.lowVisible,
                highVisible: frequencyMarketProperties.fcrD.highVisible,
            });
            setFrequencyAnnotations({
                yaxis: [
                    {
                        y: frequencyMarketProperties.fcrD.lineMin,
                        borderColor: '#219e4d',
                        label: {
                            borderColor: '#219e4d',
                            style: {
                                color: '#fff',
                                background: '#219e4d',
                                fontSize: '0.8vw',
                            },
                            text: String(frequencyMarketProperties.fcrD.lineMin),
                        },
                    },
                ],
            });
        } else {
            setYaxisVisible({});
            setFrequencyAnnotations(null);
        }
    };

    const getDownloadData = (prefillData) => {
        const params = {
            start: startTime,
            end: endTime,
            date: new Date(moment(date).tz('Europe/Berlin').format()),
            prefillData: prefillData,
            dataDecimation: false,
            edgeId: profile?.value,
            isEdgeCapacity: edgeCapacity,
        };

        signalsServiceForRoot.getActivationProfile(params, startLoader, handleDownloadSuccess, handleError, stopLoader);
    };

    const handleDownloadSuccess = ({ data }) => {
        handleExcelDownload(data);
    };

    const handleExcelDownload = (data) => {
        const downloadData = data?.data?.downloadable;
        let columnIndex = 0;
        let columnLength = 0;
        const flattenedData = downloadData
            .map((item) => flatten(item, { delimiter: '_' }))
            .map((item, index) => {
                const timestampCET = item.timestampCET;
                delete item.timestampCET;
                const objectLength = Object.keys(item).length;
                if (objectLength > columnLength) {
                    columnLength = objectLength;
                    columnIndex = index;
                }
                return {
                    timestampCET,
                    ...item,
                };
            });

        const columnNames = ['TIMESTAMP (CET)'];
        for (let key in flattenedData[columnIndex]) {
            if (key !== 'timestampCET') {
                columnNames.push(key.toUpperCase());
            }
        }
        DownloadAsExcel(flattenedData, `Frequency - ${profile?.signalName}`, columnNames, columnIndex);
    };

    const addZeroInFrequency = (freq) => {
        let newFrequency = freq;
        for (let step = 0; step < 5; step++) {
            if (newFrequency.length < 5) {
                newFrequency = newFrequency + 0;
            } else {
                break;
            }
        }
        return newFrequency;
    };

    const autoRefeshData = () => {
        setEndTime(moment().tz('Europe/Berlin').add(1, 'minutes').format('HH:mm:ss'));
        const params = {
            start: startTime,
            end: moment().tz('Europe/Berlin').add(1, 'minutes').format('HH:mm:ss'),
            date: moment(date).format('YYYY-MM-DD'),
            prefillData: false,
            dataDecimation: true,
            edgeId: profile?.value,
            isEdgeCapacity: edgeCapacity,
        };
        signalsServiceForRoot.AutoRefreshActivationProfile(params, handleSuccess, handleError);
    };

    useEffect(() => {
        if (isAutoRefresh) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else {
                    autoRefeshData();
                    setSeconds(30);
                }
            }, 1000);
            setCounterInterval(interval);
            return () => clearInterval(interval);
        }
    }, [isAutoRefresh, seconds]);

    useEffect(() => {
        if (isAutoRefresh) {
            const currentTime = moment().tz('Europe/Berlin').format('HH:mm:ss');
            const curCetDate = moment().tz('Europe/Berlin');
            setDate(new Date(curCetDate.format()));
            setEndTime(currentTime);
        }
    }, [isAutoRefresh]);

    useEffect(() => {
        if (selectedIndex === 3) {
            fetchIntMeta();
        }
    }, [selectedIndex, profile?.instanceId]);

    const fetchIntMeta = () => {
        const params = {
            instanceId: profile.instanceId,
            date: moment(date).format('YYYY-MM-DD'),
            start: startTime,
            end: endTime,
        };
        RootActivationProfileService.getAllIntMeta(
            params,
            () => startLoader('getAllIntMeta'),
            handleIntMetaSuccess,
            handleError,
            () => stopLoader('getAllIntMeta')
        );
    };

    const handleIntMetaSuccess = ({ data }) => {
        if (data?.data) {
            setIntMeta(data.data);
        } else {
            setIntMeta([]);
        }
    };

    const setActivationTime = (startTime, endTime) => {
        setStartTime(moment(startTime).subtract(3, 'seconds').tz('Europe/Berlin').format('HH:mm:ss'));
        setEndTime(moment(endTime).add(1, 'minutes').tz('Europe/Berlin').format('HH:mm:ss'));
        getData(moment(startTime).subtract(3, 'seconds').tz('Europe/Berlin').format('HH:mm:ss'), moment(endTime).add(1, 'minutes').tz('Europe/Berlin').format('HH:mm:ss'));
    };

    const getChipsColor = (chip) => {
        let color = {};
        const timeToReact = chip.secToReachNonZeroPower && Number(chip.secToReachNonZeroPower);
        if (chip.status === 'Activation Successful') {
            if (chip?.maxFrequency >= profile?.frequency?.p120 || chip?.minFrequency <= profile?.frequency?.p80) {
                if ((timeToReact || timeToReact === 0) && timeToReact > 3) {
                    color = {
                        background: 'linear-gradient(to right, #147439 95%, #FFA500 5%)',
                    };
                } else {
                    color = { background: '#147439' };
                }
            } else {
                if ((timeToReact || timeToReact === 0) && timeToReact > 3) {
                    color = {
                        background: 'linear-gradient(to right, #1FB559 95%, #FFA500 5%)',
                    };
                } else {
                    color = { background: '#1FB559' };
                }
            }
        } else if (
            chip.status === 'Under Delivered' ||
            chip.status === 'Over Delivered' ||
            chip.status === 'No Power' ||
            chip.status === 'Power & no Command' ||
            chip.status === 'No Activation'
        ) {
            if ((timeToReact || timeToReact === 0) && timeToReact > 3) {
                color = {
                    background: 'linear-gradient(to right, red 95%, #FFA500 5%)',
                };
            } else {
                color = { background: 'red' };
            }
        }
        return color;
    };
    let tabs = [
        {
            name: 'Power',
            component: (
                <div>
                    <div>
                        <div className={activationClasses.chipsContainer}>
                            {activationLogs.map((item) => (
                                <div className={activationClasses.chips} onClick={() => setActivationTime(item.startTime, item.endTime)} style={getChipsColor(item)}>
                                    {moment(item.startTime).tz('Europe/Berlin').format('HH:mm:ss')} - {getDuration(item.startTime, item.endTime)}
                                    <br />
                                    {item.maxFrequency > 0 ? (
                                        <span>Max Frequency - {truncateNumber(Number(item?.maxFrequency), 3)}</span>
                                    ) : item?.minFrequency < 100 ? (
                                        <span>Min Frequency - {truncateNumber(Number(item?.minFrequency), 3)}</span>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <ChartComponent
                            series={graphData}
                            title={`${profile?.signalName}`}
                            type="line"
                            xFormatter={timeOnlyWithms}
                            lastReported={lastStatus}
                            yFormatter={formatYAxisValue}
                            chartProps={{
                                group: 'signalLog',
                            }}
                            rightSideElement={
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.5vw',
                                    }}
                                >
                                    <ToggleButtonWithState
                                        disabled={disableAutoRefresh}
                                        label={'Auto Refresh'}
                                        value={isAutoRefresh}
                                        style={{ marginTop: '0' }}
                                        onChange={(e) => {
                                            setIsAutorefresh(e.target.checked);
                                            clearInterval(counterInterval);
                                            setSeconds(30);
                                        }}
                                    />
                                    {isAutoRefresh && (
                                        <label
                                            style={{
                                                fontSize: '0.72vw',
                                                fontWeight: '500',
                                                marginLeft: '-4px',
                                            }}
                                        >
                                            in {seconds} seconds
                                        </label>
                                    )}
                                </div>
                            }
                        />

                        <ChartComponent
                            series={powerData}
                            title={'Power'}
                            type="line"
                            xFormatter={timeOnlyWithms}
                            lastReported={lastStatus}
                            yFormatter={formatYAxisValue}
                            chartProps={{
                                group: 'signalLog',
                            }}
                            rightSideElement={
                                <ThreeStateToggle
                                    labels={CAPACITY_OPTIONS}
                                    onChange={(e) => {
                                        switch (e) {
                                            case 'left': {
                                                setEdgeCapacity(false);
                                                break;
                                            }
                                            case 'right': {
                                                setEdgeCapacity(true);
                                                break;
                                            }
                                        }
                                    }}
                                    defaultValue={'right'}
                                />
                            }
                        />
                    </div>
                </div>
            ),
            tabId: 2,
        },
        {
            name: 'Activations',
            component: (
                <Summary
                    date={date}
                    edgeId={profile?.value}
                    onSubmit={summarySubmitEvent}
                    updateQueryParams={updateQueryParams}
                    setSelectedIndex={setSelectedIndex}
                    setStartTime={setStartTime}
                    setEndTime={setEndTime}
                />
            ),
            tabId: 2,
        },
        {
            name: 'Events',
            component: <Events profile={profile} date={date} queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />,
            tabId: 2,
        },
        {
            name: 'INT Meta',
            component: <IntMeta intMeta={intMeta} profile={profile} />,
            tabId: 2,
        },
    ];

    useEffect(() => {
        getOptionFilters();
    }, []);

    useEffect(() => {
        if (selectedCustomer?.value) {
            getProfiles();
        }
    }, [selectedCustomer]);

    const getOptionFilters = () => {
        RootActivationProfileService.GetOptionsFilters(startLoader, handleFiltersSuccess, handleError, stopLoader);
    };

    const handleFiltersSuccess = ({ data }) => {
        const adminDropdown = [
            {
                label: 'All',
                value: 'all',
            },
        ];

        if (data?.data) {
            const admins = data.data;
            // admin level
            for (let admin in admins) {
                const curAdmin = admins[admin];
                adminDropdown.push({
                    label: curAdmin.name,
                    value: curAdmin.id,
                });
            }
            setRawData(admins);
        }
        setAdmins(adminDropdown);
    };

    const handleAdminUpdate = (e = null) => {
        e && setSelectedAdmin(e);
        const adminId = e ? e.value : selectedAdmin.value;
        if (adminId !== 'all') {
            const matchedAdmin = rawData[adminId];
            const customerDropDown = [
                {
                    label: 'All',
                    value: 'all',
                },
            ];
            if (matchedAdmin) {
                const customers = matchedAdmin.customer;

                for (let customer in customers) {
                    const curCustomer = customers[customer];
                    customerDropDown.push({
                        label: curCustomer.name,
                        value: customer,
                    });
                }
            }
            setCustomers(customerDropDown);
        } else if (adminId === 'all') {
            // if all is selected in dropdown, to get all profiles
            setCustomers([{ label: 'All', value: 'all' }]);
            handleFiltersSuccess({ data: { data: rawData } });
        }
    };

    const handleCustomerUpdate = (e = null) => {
        e && setSelectedCustomer(e);
    };

    const getProfiles = () => {
        RootActivationProfileService.GetSelectedProfile({ customerId: selectedCustomer?.value }, startLoader, handleProfileSuccess, handleError, stopLoader);
    };

    const handleProfileSuccess = ({ data }) => {
        const profileData = [];
        data.data.map((item) => {
            if (item.instance?.[0]) {
                profileData.push({
                    label: `${item.instance?.[0]} ${item?.profile?.market}`,
                    value: item._id,
                    signalName: item?.signal?.[0]?.name || '',
                    ...item.profile,
                    frequency: item.frequency,
                });
            }
        });
        setProfiles(profileData);
        setProfile(queryParamsData.profile ? JSON.parse(queryParamsData.profile) : profileData[0]);
    };

    const downloadEnerginetFormatData = () => {
        if (profile.value) {
            const params = {
                profileId: profile.value,
                start: startTime,
                end: endTime,
                date: momentTimeFormater(date).format('YYYY-MM-DD'),
            };
            RootActivationProfileService.getEnerginetFormatData(params, startLoader, handleEnerginetFormatDataSuccess, handleError, stopLoader);
        }
    };

    const handleEnerginetFormatDataSuccess = ({ data }) => {
        const base64String = data.data.base64String;
        const templateBase64String = data.data.templateBase64String;
        DownloadAsExcelWithBuffer(base64String, `Energinet Format - ${profile.label}`);
    };

    const downLoadEnergynetAudit = (type, downloadHandler) => {
        let params = {
            start: startTime,
            end: endTime,
            date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
            signalId: profile?.signalId,
            type: type,
        };

        signalsServiceForRoot.downLoadEnergynetAudit(
            params,
            () => startLoader('read-data'),
            downloadHandler,
            handleError,
            () => stopLoader('read-data')
        );
    };

    const handleDownloadEnergynetAudit = ({ data }) => {
        let formatedData = [];
        let formatedSimulatedData = [];
        data.data.data.map((e) => {
            let temp = {
                date: momentTimeFormater(e.date).format('DD/MM/YYYY'),
                time: e.time,
                frequency: e.frequency,
                capacitySold: e.soldCapacity,
                targetCapacity: e.targetCapacity,
                '-5%': e.minusFivePercent,
                load: e.load,
                '+20%': e.plusTwentyPercent,
                ...(!data.data?.basePower && {
                    deltaLoad: e.deltaLoad,
                    status: e.status,
                }),
            };
            if (data.data?.basePower) {
                temp.basePower = e.basePower;
                temp.deltaLoad = e.deltaLoad;
                temp.status = e.status;
            }
            formatedData.push(temp);
            if (data.data?.isSimulatedPower) {
                let simulatedTemp = {
                    date: momentTimeFormater(e.date).format('DD/MM/YYYY'),
                    time: e.time,
                    frequency: e.frequency,
                    capacitySold: e.soldCapacity,
                    targetCapacity: e.simulatedTargetCapacity,
                    '-5%': e.simulatedMinusFivePercent,
                    load: e.simulatedLoad,
                    '+20%': e.simulatedPlusTwentyPercent,
                    deltaLoad: e.deltaLoad,
                    status: e.simulatedStatus,
                };
                formatedSimulatedData.push(simulatedTemp);
            }
        });

        let headers = ['Date (CET)', 'Time (CET)', 'Frequency', 'Capacity Sold', 'Target Capacity', '-5%', 'Load', '+20% '];

        if (data.data?.basePower) {
            headers.push(...['Base Power', 'Delta Load', 'Status']);
        } else {
            headers.push(...['Delta Load', 'Status']);
        }

        data.data.data.length && DownloadAsExcel(formatedData, `${data.data?.customerName}_${data.data?.instanceName}`, headers);
        if (formatedSimulatedData.length) {
            DownloadAsExcel(formatedSimulatedData, `${data.data?.instanceName} Simulated Power`, [
                'Date (CET)',
                'Time (CET)',
                'Frequency',
                'Capacity Sold',
                'Target Capacity',
                '-5%',
                'Simulated Power',
                '+20% ',
                'Delta Load',
                'Status',
            ]);
        }
    };

    const handlePQFequencyDownload = () => {
        let params = {
            start: startTime,
            end: endTime,
            date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
            signalId: profile?.signalId,
        };

        signalsServiceForRoot.pqFrequencyDownload(params, startLoader, handlePQFreqencySuccess, handleError, stopLoader);
    };

    const handlePQFreqencySuccess = ({ data }) => {
        constructEnerginetFrequency(data.data, `PQ Frequency - ${profile.label}`);
    };

    return (
        <div>
            <ModalComponent isOpen={PQModal} setOpen={setPQModal} style={{ overflow: 'initial' }}>
                <PQDownload setPQModal={setPQModal} startTime={startTime} endTime={endTime} signalId={profile?.signalId} date={date} />
            </ModalComponent>
            <div className={activationClasses.Filter}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className={activationClasses.FilterContainer}>
                        <div>
                            <div className={classes.FieldControl}>
                                <label>Admin</label>
                                <DropdownComponent
                                    name="admin"
                                    options={admins}
                                    onChange={(e) => {
                                        handleAdminUpdate(e);
                                        updateQueryParams('admin', JSON.stringify(e));
                                    }}
                                    defaultValue={selectedAdmin}
                                />
                            </div>
                        </div>

                        <div>
                            <div className={classes.FieldControl}>
                                <label>Customer</label>
                                <DropdownComponent
                                    name="customerId"
                                    options={customers}
                                    onChange={(e) => {
                                        if (e.value !== selectedCustomer?.value) {
                                            handleCustomerUpdate(e);
                                        }
                                        updateQueryParams('customer', JSON.stringify(e));
                                    }}
                                    defaultValue={selectedCustomer}
                                />
                            </div>
                        </div>
                        <div>
                            <div className={classes.FieldControl}>
                                <label>Edge Profiles</label>
                                <DropdownComponent
                                    name="profileId"
                                    options={profiles}
                                    onChange={(e) => {
                                        if (e.value !== profile?.value) {
                                            setProfile(e);
                                        }
                                        updateQueryParams('profile', JSON.stringify(e));
                                    }}
                                    defaultValue={profile}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'end' }}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <div style={{ display: 'flex', gap: '0.521vw' }}>
                                <div className={activationClasses.FieldInput}>
                                    <label>Date</label>
                                    <DatePicker date={date} setDate={setDate} showArrow={true} />
                                </div>
                                {[0, 3].includes(selectedIndex) && (
                                    <div className={activationClasses.FieldInput}>
                                        <label>Start Time</label>
                                        <CustomTimePicker
                                            startTime={startTime}
                                            setStartTime={(e) => {
                                                setStartTime(e);
                                                updateQueryParams('startTime', e);
                                            }}
                                            type="start"
                                            style={isError ? { borderColor: 'red' } : {}}
                                            maxDetail={'second'}
                                        />
                                    </div>
                                )}

                                {[0, 3].includes(selectedIndex) && (
                                    <div className={activationClasses.FieldInput}>
                                        <label>End Time</label>
                                        <CustomTimePicker
                                            endTime={endTime}
                                            setEndTime={(e) => {
                                                setEndTime(e);
                                                updateQueryParams('endTime', e);
                                            }}
                                            type="end"
                                            style={isError ? { borderColor: 'red' } : {}}
                                            disabled={isAutoRefresh}
                                            maxDetail={'second'}
                                        />
                                    </div>
                                )}
                            </div>
                            <div style={{ display: 'flex', alignItem: 'baseline' }}>
                                <button
                                    type="submit"
                                    className="btn-primary"
                                    style={{
                                        marginTop: '1.5vw',
                                        width: 'auto',
                                        marginLeft: '0.5vw',
                                    }}
                                    onClick={() => {
                                        selectedIndex === 3 ? fetchIntMeta() : getData();
                                        setSummarySubmitEvent((prev) => prev + 1);
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalComponent isOpen={open} setOpen={setOpen}>
                <AddProfile setOpen={setOpen} setRefresh={setRefresh} editProfile={editProfile} />
            </ModalComponent>

            <div className={activationClasses.DeviceDashboard}>
                <div className={classes.Overview} style={{ width: '70vw' }}>
                    <div className={classes.Header}>
                        <Typography content={profile?.name} />
                        <div style={{ display: 'flex', alignItem: 'baseline', gap: '0.5vw' }}>
                            <Menu
                                menuButton={
                                    <div>
                                        <UilImport
                                            size="1.2vw"
                                            style={{
                                                cursor: 'pointer',
                                                marginTop: '0.4vw',
                                            }}
                                        />
                                    </div>
                                }
                                direction={'bottom'}
                                arrow={true}
                                menuClassName={activationClasses.Menu}
                            >
                                <SubMenu className={activationClasses.MenuOptions} label={<div>Frequency Power</div>}>
                                    <div>
                                        <MenuItem onClick={() => getDownloadData(false)} className={activationClasses.MenuOptions}>
                                            <div>Raw Download</div>
                                        </MenuItem>
                                    </div>
                                    <div>
                                        <MenuItem onClick={() => getDownloadData(true)} className={activationClasses.MenuOptions}>
                                            <div>Advance Download</div>
                                        </MenuItem>
                                    </div>
                                </SubMenu>
                                <MenuItem onClick={() => downLoadEnergynetAudit('energinetAudit', handleDownloadEnergynetAudit)} className={activationClasses.MenuOptions}>
                                    <div>Energinet Audit Download</div>
                                </MenuItem>
                                <MenuItem onClick={() => setPQModal(true)} className={activationClasses.MenuOptions}>
                                    <div>PQ Download</div>
                                </MenuItem>
                                <MenuItem onClick={() => handlePQFequencyDownload()} className={activationClasses.MenuOptions}>
                                    <div>PQ Frequency Download</div>
                                </MenuItem>
                                <MenuItem onClick={downloadEnerginetFormatData} className={activationClasses.MenuOptions}>
                                    <div>Energinet Format</div>
                                </MenuItem>

                                <SubMenu className={activationClasses.MenuOptions} label={<div>Energinet Format - Template</div>}>
                                    <div>
                                        <MenuItem className={activationClasses.MenuOptions}>
                                            <a target="_blank" href="https://truegreen-uploads.s3.eu-central-1.amazonaws.com/newEnerginetTemplate.xlsx">
                                                FCR/FFR/FCR-D
                                            </a>
                                        </MenuItem>
                                    </div>
                                    <div>
                                        <MenuItem className={activationClasses.MenuOptions}>
                                            <a target="_blank" href="https://truegreen-uploads.s3.eu-central-1.amazonaws.com/energinetTemplateAfrr.xlsx">
                                                aFRR/mFRR
                                            </a>
                                        </MenuItem>
                                    </div>
                                </SubMenu>
                            </Menu>
                        </div>
                    </div>
                    <div className={classes.OverviewBorder}></div>
                    <div className={activationClasses.TabsContainer}>
                        <TabsComponent
                            selectedIndex={selectedIndex}
                            tabs={tabs}
                            onChangeTab={(e) => {
                                setSelectedIndex(e);
                                updateQueryParams('subTab', e);
                                setGraphData([]);
                                setPowerData([]);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActivationProfiler;
