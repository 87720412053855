// External library import
import * as yup from 'yup';

// Internal module imports
import { ERRORS } from '../../utils/validationHelper';

yup.addMethod(yup.number, 'multipleOfTen', function (message) {
    return this.test('multipleOfTen', message, function (value) {
        const { path, createError } = this;
        return (
            value % 10 === 0 ||
            createError({
                path,
                message: message ?? `${value} is not a multiple of 10`,
            })
        );
    });
});

export const EdgeControlTest = (field) => {
    return yup.object().shape({
        setPoint: yup.number().min(0, 'Must be greater than or equal to 0').max(100, 'Must be lesser than or equal to 100').multipleOfTen('Number must be a multiple of 10').required(ERRORS.required)
    });
};
