import classes from './index.module.css';
import React, { useState } from 'react';
import TabsComponent from '../../../components/Tabs/Tabs';
import Areas from './Areas';
import SignalTypes from './SignalTypes';
import Signals from './Signals';
import ExternalApisRoot from './ExternalApi';
import Cron from '../GlobalConfig/CronJobs/Cron';
import DataInjestForm from './DataInjest/DataInjestForm';
import SystemConfig from './SystemConfig';
import { ForceActivation } from './ForceActivation';
// External library imports
import { useLocation, useHistory } from 'react-router-dom';
import EdgeProfile from './EdgeProfiles';

const GlobalConfig = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const [queryParamsData, setQueryParams] = useState({
        tab: queryParams.get('tab') ? Number(queryParams.get('tab')) : '',
    });
    const updateQueryParams = (type, value) => {
        queryParams.set(type, value);
        history.push({ search: queryParams.toString() });
    };
    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.tab ? queryParamsData.tab : 0);
    let tabs = [
        {
            name: 'System Config',
            component: (
                <>
                    <SystemConfig />
                </>
            ),
            tabId: 1,
        },
        {
            name: 'Cron Jobs',
            component: (
                <>
                    <Cron />
                </>
            ),
            tabId: 2,
        },
        {
            name: 'Data Injest',
            component: (
                <>
                    <DataInjestForm />
                </>
            ),
            tabId: 3,
        },
        {
            name: 'External API',
            component: <ExternalApisRoot />,
            tabId: 4,
        },
        {
            name: 'Edge Profile',
            component: <EdgeProfile />,
            tabId: 5,
        },
        {
            name: 'Force Activation',
            component: <ForceActivation />,
            tabId: 6,
        },
    ];

    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <TabsComponent
                selectedIndex={selectedIndex}
                tabs={tabs}
                onChangeTab={(index) => {
                    setSelectedIndex(index);
                    updateQueryParams('tab', index);
                }}
            />
        </div>
    );
};

export default GlobalConfig;
