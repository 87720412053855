// external library imports
import ExcelJS from 'exceljs';
// Internal module imports
import { DownloadFileFromBuffer } from './downloadHelper';
import { setCellData } from './exceljsHelper';

export const constructEnerginetFrequency = async (data, fileName) => {
    const colors = {
        darkGreen: '008B8B',
        lightGreen: 'A0C1C2',
        white: 'FFFFFF',
    };

    const boldFont = { bold: true };

    const borderStyle = {
        border: {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        },
    };

    const fontSize = (size = 11) => ({ size });

    const backgroundColor = (color) => ({
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: colors[color] },
        },
    });

    const fontColor = (color) => ({ color: { argb: colors[color] } });

    const alignMiddle = {
        alignment: { horizontal: 'center', vertical: 'middle' },
    };

    const alignLeft = { alignment: { vertical: 'middle', horizontal: 'left' } };

    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(fileName);

    // Increase the height of row
    sheet.getRow(1).height = 20;
    sheet.getRow(2).height = 15;

    const columnWidth = 30.5703125;

    // Set column widths
    sheet.getColumn(1).width = columnWidth;
    sheet.getColumn(2).width = columnWidth;
    sheet.getColumn(3).width = columnWidth;
    sheet.getColumn(4).width = columnWidth;
    sheet.getColumn(5).width = columnWidth;
    sheet.getColumn(6).width = columnWidth;

    sheet.mergeCells('A1:F1');
    setCellData(sheet, 'B1', 'Data Sheet for Prequalification of Frequency Reserves', {
        font: { ...boldFont, ...fontSize(18), ...fontColor('white') },
        alignment: alignMiddle.alignment,
        ...backgroundColor('darkGreen'),
    });

    setCellData(sheet, `A2`, 'Price area', {
        font: { ...boldFont, ...fontColor('white'), ...fontSize(12) },
        alignment: alignLeft.alignment,
        ...backgroundColor('darkGreen'),
        ...borderStyle,
    });

    setCellData(sheet, `B2`, '', {
        ...backgroundColor('lightGreen'),
        ...borderStyle,
    });

    // set headers
    setCellData(sheet, `A5`, 'Datetime', {
        font: { ...boldFont, ...fontColor('white') },
        alignment: alignMiddle.alignment,
        ...backgroundColor('darkGreen'),
        ...borderStyle,
    });

    setCellData(sheet, `B5`, 'Frequency [Hz]', {
        font: { ...boldFont, ...fontColor('white') },
        alignment: alignMiddle.alignment,
        ...backgroundColor('darkGreen'),
        ...borderStyle,
    });
    //
    const startRow = 6;
    data.forEach((row, idx) => {
        setCellData(sheet, `A${startRow + idx}`, row.utcDate, {
            ...borderStyle,
            ...backgroundColor('lightGreen'),
        });

        setCellData(sheet, `B${startRow + idx}`, row.frequency, {
            ...borderStyle,
            ...backgroundColor('lightGreen'),
        });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    DownloadFileFromBuffer(buffer, fileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
};
