import * as yup from 'yup';
import { ERRORS, phoneRegExp } from '../../utils/validationHelper';

export const createEnabler = () => {
    return yup.object().shape({
        name: yup.string().required(ERRORS.required),
        email: yup.string().email().required(ERRORS.email),
        password: yup.string().required(ERRORS.required),
        countryCode: yup.string().required(ERRORS.required),
        contact: yup.string().matches(phoneRegExp, 'invalid contact number').required(ERRORS.required),
        accessBlocked: yup.boolean().required(ERRORS.required),
        address: yup.object({
            invoiceInfo: yup.array().of(
                yup.object().shape({
                    name: yup.string().optional(),
                    email: yup.string().email().optional(),
                })
            )
        })
    });
};

export const updateEnabler = () => {
    return yup.object().shape({
        name: yup.string().required(ERRORS.required),
        email: yup.string().email().required(ERRORS.email),
        password: yup.string().optional(),
        countryCode: yup.string().required(ERRORS.required),
        contact: yup.string().matches(phoneRegExp, 'invalid contact number').required(ERRORS.required),
        accessBlocked: yup.boolean().required(ERRORS.required),
        address: yup.object({
            invoiceInfo: yup.array().of(
                yup.object().shape({
                    name: yup.string().optional(),
                    email: yup.string().email().optional(),
                })
            )
        })
    });
};
