// Standard library import
import React, { useEffect, useState } from 'react';

// External library import
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import {
    UilKeyholeCircle,
    UilChartLine,
    UilInfoCircle,
    UilMessage,
    UilTrash,
    UilExclamationOctagon,
    UilPen,
    UilDocumentInfo,
    UilStopwatch,
    UilBellSlash,
    UilEllipsisV,
    UilTachometerFastAlt,
    UilFileUpload,
    UilArrowsResize,
    UilCreateDashboard,
    UilAnalysis
} from '@iconscout/react-unicons';
import { MenuItem, Menu, MenuDivider, SubMenu } from '@szhsin/react-menu';
import moment from 'moment';

// Internal module import
import ModalComponent from '../../components/ModalComponent/ModalComponent';
import Table from '../../components/Table/Table';
import Typography from '../../components/Typography/Typography';
import { CustomerService } from '../../services/CustomerService';
import { useLoader } from '../../hooks';
import { EdgeService } from '../../services/EdgeService';
import Device from './ModalComponent/create';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import { formatDuration, momentTimeFormater } from '../../utils/timeHelper';
import Credentials from './ModalComponent/credentials';
import CustomTooltip from '../../components/CustomToolTip/CustomTooltip';
import Commands from './ModalComponent/commands';
import { DropdownComponent, ToggleButtonWithState } from '../../components/Inputs/Input';
import Dropdown from '../../components/Inputs/Dropdown';
import { ApikeyService } from '../../services/apiKeyService';
import { BID_STATUS_API_URL, LICENSE_DETAILS_URL, SNOOZE_MINUTES } from '../../constants';
import EdgeInfo from './ModalComponent/edgeInfo';
import DocumentEditor from './ModalComponent/DocumentEditor';
import { SnoozeService } from '../../services/snoozeService';
import Schedule from './ModalComponent/Schedule';
import { CamelCaseToTextCase } from '../../utils/stringHelper';
import ScheduleTestIndex from './ModalComponent/ScheduleTestIndex';
import ForceFactorActivation from './ModalComponent/ForceFactorActivationModal';

// CSS Imports
import classes from '../../styles/Bids.module.css';
import EdgeClasses from './Edge.module.css';
import RedirectToSignalViwerOrEdgeDashboard from './ModalComponent/Redirect';

const statusOptions = [
    { value: false, label: 'Enabled' },
    { value: true, label: 'Disabled' },
];

const Edge = ({
    setSelectedIndex,
    setEdgeError,
    setEdgeDeviceId,
    setEdgeStats,
    setDashboard,
    setUploadLogFiles,
    updateQueryParams,
    queryParamsData = {},
    setTestLogs,
    setSelectedEdge,
}) => {
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);
    const [deviceModal, setDeviceModal] = useState({
        status: false,
        device: {},
        createDuplicate: false
    });
    const [commandModal, setCommandModal] = useState({
        status: false,
        device: {},
    });
    const [deleteModal, setDeleteModal] = useState({ status: false, id: '' });
    const [skip, setSkip] = useState(0);
    const [limit] = useState(15);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [customers, setCustomers] = useState([]);
    const [selectCustomer, setSelectCustomer] = useState({
        value: '',
        label: '',
        customerCode: '',
        license: {},
    });
    // const [credentialModal, setCredentialModal] = useState({
    //   status: false,
    //   device: {},
    // });
    const [isOpenDocumentModal, setOpenDocumentModal] = useState({
        status: false,
        data: {},
    });
    // const [updateConfirmModal, setUpdateConfirmModal] = useState(false);
    const [infoModal, setInfoModal] = useState({
        status: false,
        data: '',
        licenseDetailUrl:
            window.location.hostname === 'tgadmin.scnordic.com'
                ? `https://api.edge.truegreen.scnordic.com/${LICENSE_DETAILS_URL}`
                : `https://beta.api.edge.truegreen.scnordic.com/${LICENSE_DETAILS_URL}`,
        bidStatusApiUrl:
            window.location.hostname === 'tgadmin.scnordic.com'
                ? `https://api.edge.truegreen.scnordic.com/${BID_STATUS_API_URL}`
                : `https://beta.api.edge.truegreen.scnordic.com/${BID_STATUS_API_URL}`,
    });

    const [scheduleModal, setScheduleModal] = useState({
        status: false,
        data: '',
    });
    const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
    const [redirectTo, setRedirectTo] = useState({
        status: false,
        data: {},
    });
    const [forceFactorModal, setForceFactorModal] = useState({
        status: false,
        edgeData: {},
    });

    useEffect(() => {
        if (selectCustomer.value) {
            getData();
        }
    }, [skip, limit, selectCustomer, selectedStatus.value]);

    useEffect(() => {
        fetchCustomers();
    }, []);

    const fetchCustomers = () => {
        CustomerService.ReadAll({ isActive: true }, startLoader, customerSuccessHandler, handleError, stopLoader);
    };

    const matchQueryParams = (data = [], match = {}) => {
        for (let item of data) {
            if (item.value === match?.value) {
                return item;
            }
        }
        return null;
    };

    const customerSuccessHandler = ({ data }) => {
        let customers = data.data;
        let temp = [];
        customers.forEach((element) => {
            temp.push({
                value: element._id,
                label: element.name,
                customerCode: element.customerCode,
                license: element.license,
            });
        });
        setCustomers(temp);
        if (temp.length) {
            const queryMatched = matchQueryParams(temp, { value: queryParamsData.customer });
            setSelectCustomer(queryMatched ? queryMatched : temp[0]);
            !queryMatched && updateQueryParams('customer', temp[0].value);
        } else {
            setSelectCustomer({
                value: '',
                label: '',
                customerCode: '',
                license: {},
            });
        }
    };

    const getData = () => {
        const params = {
            limit,
            skip,
            customerId: selectCustomer.value,
            disabled: selectedStatus.value,
        };
        return EdgeService.ReadAll(params, startLoader, handleGetDataSuccess, handleError, stopLoader);
    };

    const alertOnChangeHandler = (deviceDetails, e) => {
        if (deviceDetails?._id) {
            const payload = {
                value: e.target.checked,
                type: 'master',
                edgeDeviceId: deviceDetails.deviceId,
            };
            EdgeService.UpdateNotificationStates(payload, startLoader, handleUpdateSucess, handleError, stopLoader);
        }
    };

    const handleUpdateSucess = ({ res }) => {
        getData();
        toast.success('Device updated successfully');
    };

    const snooze = (id, value, type, resource, resourceName) => {
        const payload = {
            resourceId: id,
            type: type,
            snoozeForMinutes: value.value,
            resource,
            resourceName,
        };
        SnoozeService.Create(payload, startLoader, () => handleSnoozeSuccess(value.label), handleError, stopLoader);
    };

    const handleSnoozeSuccess = (value) => {
        if (value === 'Cancel Snooze') {
            toast.success(`Snooze Canceled.`);
        } else {
            toast.success(`Snooze for ${value}.`);
        }
        getData();
    };
    const isSnooze = (data = [], type) => {
        const typeSnoozeStat = data.find((item) => item.type === type);
        if (typeSnoozeStat) {
            const snoozedTime = moment(typeSnoozeStat.timestamp).add(typeSnoozeStat.snoozeForMinutes, 'minutes');
            const isSnoozed = snoozedTime.isAfter(moment().utc());
            if (isSnoozed) {
                return `Snoozed Till (CET): ${momentTimeFormater(snoozedTime).format('YYYY-MM-DD-HH:mm:ss')}`;
            } else {
                return null;
            }
        } else {
            return null;
        }
    };
    const handleGetDataSuccess = ({ data }) => {
        const processedData = data?.data[0]?.data.map((item) => {
            const difference = moment().utc().diff(moment(item?.lastReported).utc(), 'milliseconds');
            const lastReported = formatDuration(difference, 'milliseconds');

            const apiKeyData = {
                status: true,
                data: item?.ecrRepositoryUri || '',
                customerId: selectCustomer?.value,
                userName: item?.properties?.username || '',
                password: item?.properties?.password || '',
                systemId: item?.systemId || '',
                ecrRepositoryName: item?.ecrRepositoryName || '',
                edgeId: item.deviceId,
                instanceId: item?.groupId || '',
                createdAt: item?.createdAt ? momentTimeFormater(item.createdAt).format('YYYY-MM-DD HH:mm:ss') : '',
                licenseCode: item.licenseTypeData?.length && item.instanceData?.length ? item.licenseTypeData[0].licenseCode : '--',
                edgeAlias: item.edgeAlias
            };

            return {
                ...item,
                apiKeyData,
                lastReported: item.lastReported ? lastReported : '--',
                licenseAndInstance:
                    item.licenseTypeData?.length && item.instanceData?.length
                        ? `${CamelCaseToTextCase(item.licenseTypeData[0].name)}/${item.instanceData[0].name}`
                        : item.licenseTypeData?.length
                            ? `${CamelCaseToTextCase(item.licenseTypeData[0].name)}/--`
                            : item.instanceData?.length
                                ? `--/${item.instanceData[0].name}`
                                : '--',
                codeVersion: item.properties?.codeVersion ? item.properties.codeVersion : '--',
                action: (
                    <div
                        style={{
                            display: 'flex',
                            gridColumnGap: '1vw',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CustomTooltip content={'Dashboard'}>
                            <div
                                onClick={() => {
                                    setSelectedIndex(5);
                                    updateQueryParams('isDashboard', true);
                                    updateQueryParams('tab', 5);
                                    setDashboard({
                                        show: true,
                                        data: {
                                            edgeId: item.deviceId,
                                            _id: item._id,
                                            selectedCustomer: selectCustomer,
                                            profile: item?.properties?.profile,
                                            frequency: item?.properties?.frequency,
                                        },
                                    });
                                }}
                            >
                                <UilTachometerFastAlt size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                            </div>
                        </CustomTooltip>

                        <Menu
                            menuButton={
                                <div>
                                    <CustomTooltip content={'Snooze'}>
                                        <UilBellSlash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    </CustomTooltip>
                                </div>
                            }
                            direction={'left'}
                            arrow={true}
                            menuClassName={EdgeClasses.Menu}
                        >
                            <MenuItem>
                                <div className={EdgeClasses.SnoozeHeader}>Snooze</div>
                            </MenuItem>
                            <MenuDivider />
                            <SubMenu
                                label={
                                    <div style={{ textAlign: 'left' }}>
                                        <span style={{ fontSize: '0.8vw', fontWeight: 500 }}>Data Monitoring</span>
                                        <br />
                                        <span style={{ color: '#faa500' }}>{isSnooze(item?.snoozeData, 'dataMonitoring') ? isSnooze(item?.snoozeData, 'dataMonitoring') : ''}</span>
                                    </div>
                                }
                            >
                                {SNOOZE_MINUTES.map((option, index) => (
                                    <div>
                                        <MenuItem onClick={() => snooze(item._id, option, 'dataMonitoring', 'edge', item.edgeAlias)}>
                                            <div>{option.label}</div>
                                        </MenuItem>
                                        {index < SNOOZE_MINUTES.length - 1 && <MenuDivider />}
                                    </div>
                                ))}
                            </SubMenu>
                        </Menu>

                        <CustomTooltip content={'Edit Details'}>
                            <div>
                                <UilPen size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setDeviceModal({
                                    status: true,
                                    device: item,
                                    createDuplicate: false
                                })} />
                            </div>
                        </CustomTooltip>

                        <CustomTooltip content={'Info'}>
                            <div>
                                <UilInfoCircle
                                    size={'1.2vw'}
                                    style={{ color: 'var(--color-primary)' }}
                                    onClick={() => {
                                        getApiKey(apiKeyData);
                                    }}
                                />
                            </div>
                        </CustomTooltip>

                        <Menu
                            menuButton={
                                <div>
                                    <CustomTooltip content={'Menu'}>
                                        <UilEllipsisV size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    </CustomTooltip>
                                </div>
                            }
                            direction={'left'}
                            arrow={true}
                            menuClassName={EdgeClasses.Menu}
                        >
                            {/* <MenuItem
              onClick={() => setCredentialModal({ status: true, device: item })}
              className={EdgeClasses.MenuItem}
            >
              <div className={EdgeClasses.MenuOptions}>
                <UilKeyholeCircle
                  size={"1.2vw"}
                  style={{ color: "var(--color-primary)" }}
                />
                <span>Edit MQTT Credentials</span>
              </div>
            </MenuItem>
            <MenuDivider /> */}
                            <MenuItem onClick={() => setCommandModal({ status: true, device: item })} className={EdgeClasses.MenuItem}>
                                <div className={EdgeClasses.MenuOptions}>
                                    <UilMessage size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    <span>Send Commands</span>
                                </div>
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem
                                className={EdgeClasses.MenuItem}
                                onClick={() => {
                                    setForceFactorModal({
                                        status: true,
                                        edgeData: {
                                            market: item.market,
                                            instance: {
                                                _id: item.instanceData[0]?._id,
                                                name: item.instanceData[0]?.name,
                                            },
                                        },
                                    });
                                }}
                            >
                                <div className={EdgeClasses.MenuOptions}>
                                    <UilArrowsResize pwatch size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    <span>Force Factor/Activation</span>
                                </div>
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem onClick={() => setDeleteModal({ status: true, id: item._id })} className={EdgeClasses.MenuItem}>
                                <div className={EdgeClasses.MenuOptions}>
                                    <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    <span>Delete</span>
                                </div>
                            </MenuItem>
                            <MenuDivider />

                            <MenuItem
                                className={EdgeClasses.MenuItem}
                                onClick={() => {
                                    setSelectedIndex(5);
                                    setEdgeStats(true);
                                    setEdgeDeviceId(item.deviceId);
                                }}
                            >
                                <div className={EdgeClasses.MenuOptions}>
                                    <UilChartLine className={EdgeClasses.ActionIcon} size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    <span>Stats</span>
                                </div>
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem
                                className={EdgeClasses.MenuItem}
                                onClick={() => {
                                    setSelectedIndex(5);
                                    setEdgeError(true);
                                    setEdgeDeviceId(item.deviceId);
                                    setSelectedEdge({ customerId: selectCustomer.value, ...item });
                                }}
                            >
                                <div className={EdgeClasses.MenuOptions}>
                                    <UilExclamationOctagon className={EdgeClasses.ActionIcon} size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    <span>Logs</span>
                                </div>
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem
                                className={EdgeClasses.MenuItem}
                                onClick={() => {
                                    setOpenDocumentModal({ status: true, data: item });
                                }}
                            >
                                <div className={EdgeClasses.MenuOptions}>
                                    <UilDocumentInfo className={EdgeClasses.ActionIcon} size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    <span>Document</span>
                                </div>
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem
                                className={EdgeClasses.MenuItem}
                                onClick={() => {
                                    setScheduleModal({
                                        status: true,
                                        data: { deviceId: item.deviceId, market: item.market, signalId: item.signalData?.[0]?._id },
                                    });
                                    setRedirectTo({
                                        status: false,
                                        data: item,
                                        selectedCustomer: selectCustomer,
                                    });
                                }}
                            >
                                <div className={EdgeClasses.MenuOptions}>
                                    <UilStopwatch size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    <span>Schedule Test</span>
                                </div>
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem
                                className={EdgeClasses.MenuItem}
                                onClick={() => {
                                    setSelectedIndex(5);
                                    setUploadLogFiles({
                                        status: true,
                                        data: item.deviceId,
                                    });
                                }}
                            >
                                <div className={EdgeClasses.MenuOptions}>
                                    <UilFileUpload pwatch size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    <span>Uploaded Logs</span>
                                </div>
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem
                                className={EdgeClasses.MenuItem}
                                onClick={() => {
                                    setDeviceModal({
                                        status: true,
                                        device: item,
                                        createDuplicate: true
                                    })
                                }}
                            >
                                <div className={EdgeClasses.MenuOptions}>
                                    <UilCreateDashboard pwatch size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    <span>Create Duplicate Device</span>
                                </div>
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem
                                className={EdgeClasses.MenuItem}
                                onClick={() => {
                                    redirectToSignalviewer(item);
                                }}
                            >
                                <div className={EdgeClasses.MenuOptions}>
                                    <UilAnalysis pwatch size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    <span>Signal Viewer</span>
                                </div>
                            </MenuItem>
                        </Menu>
                    </div>
                ),
                alert: (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <ToggleButtonWithState
                            value={item.properties?.notificationConfig?.master}
                            name={`properties.notificationConfig.master`}
                            onChange={(e) => alertOnChangeHandler(item, e)}
                            defaultStyle={true}
                        />
                    </div>
                ),
            };
        });
        setData(processedData);
        setTotalCount(data?.data[0]?.count[0].count);
        return processedData;
    };

    const handlePageChange = (pageno) => {
        setSkip(limit * pageno);
        setPage(pageno);
    };

    const handleDelete = () => {
        EdgeService.Delete(deleteModal.id, startLoader, handleDeleteSuccess, handleError, stopLoader);
    };

    const handleDeleteSuccess = (res) => {
        getData();
        toast.success('Device Deleted');
    };

    const handleMqttRestart = () => {
        EdgeService.UpdateMqttCredentials(startLoader, handleMqttRestartSuccess, handleError, stopLoader);
    };

    const handleMqttRestartSuccess = () => {
        toast.success('Credentials Updated: Error with MQTT Broker will be triggered on opsgenie');
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    const getApiKey = (modalData) => {
        ApikeyService.GetApiKeyForCustomerEdge(selectCustomer?.value, modalData.edgeId, startLoader, (data) => handleGetApikeySuccess(data, modalData), handleError, stopLoader);
    };

    const handleGetApikeySuccess = ({ data }, modalData) => {
        setInfoModal({
            status: true,
            data: modalData?.data || '',
            customerId: modalData?.customerId,
            apiKey: data?.data?.key || false,
            licenseDetailUrl: infoModal.licenseDetailUrl,
            bidStatusApiUrl: infoModal.bidStatusApiUrl,
            access: {
                device: data?.data?.access?.device || false,
                license: data?.data?.access?.license || false,
                bid: data?.data?.access?.bid || false,
                edge: data?.data?.access?.edge || false,
            },
            userName: modalData.userName || '',
            password: modalData.password || '',
            systemId: modalData.systemId,
            ecrRepositoryName: modalData.ecrRepositoryName,
            edgeId: modalData.edgeId,
            instanceId: modalData?.instanceId,
            createdAt: modalData?.createdAt,
            licenseCode: modalData?.licenseCode,
            edgeAlias: modalData?.edgeAlias
        });
    };

    const handleMQTTCredRotateSuccess = async (edgeId) => {
        setInfoModal({ ...infoModal, status: false });
        toast.success('New MQTT Credentials Genrated Successfully');
        const processedData = await getData();
        const infoModalActiveData = processedData.filter((item) => item.deviceId === edgeId);
        getApiKey(infoModalActiveData[0].apiKeyData);
    };

    const rotateMQTTPassword = (edgeId) => {
        EdgeService.RotateCredentials(edgeId, startLoader, () => handleMQTTCredRotateSuccess(edgeId), handleError, stopLoader);
    };

    const handleAPIKeyRotateSuccess = async (edgeId) => {
        setInfoModal({ ...infoModal, status: false });
        toast.success('API key rotated Successfully');
        const processedData = await getData();
        const infoModalActiveData = processedData.filter((item) => item.deviceId === edgeId);
        getApiKey(infoModalActiveData[0].apiKeyData);
    };

    const handleAPIKeyGenerateSuccess = async (edgeId) => {
        setInfoModal({ ...infoModal, status: false });
        toast.success('API key rotated Successfully');
        const processedData = await getData();
        const infoModalActiveData = processedData.filter((item) => item.deviceId === edgeId);
        getApiKey(infoModalActiveData[0].apiKeyData);
    };

    const rotateAPIKey = (edgeId) => {
        ApikeyService.RotateAPIKey(edgeId, startLoader, () => handleAPIKeyRotateSuccess(edgeId), handleError, stopLoader);
    };

    const redirectToSignalviewer = (data) => {
        const signal = {
            label: data?.signalData[0]?.name,
            value: data?.signalData[0]?._id,
        };

        const url = `/systemtool?tab=0&signal=${JSON.stringify(signal)}&customer=${JSON.stringify(selectCustomer)}&autoRefresh=true`;
        window.open(url, '_blank');
    };

    return (
        <div className={classes.Bids}>
            <ModalComponent isOpen={forceFactorModal.status} setOpen={() => setForceFactorModal({ status: false, edgeData: {} })}>
                <ForceFactorActivation
                    instance={forceFactorModal.edgeData?.instance}
                    market={forceFactorModal.edgeData?.market}
                    setForceModal={() => setForceFactorModal({ status: false, edgeData: {} })}
                    getEdgeData={() => { }}
                />
            </ModalComponent>
            <ModalComponent
                isOpen={redirectTo.status}
                setOpen={() => {
                    setRedirectTo({ status: false, data: {} });
                }}
            >
                <RedirectToSignalViwerOrEdgeDashboard
                    data={redirectTo.data}
                    setRedirectTo={setRedirectTo}
                    redirectToSignalviewer={redirectToSignalviewer}
                    selectedCustomer={selectCustomer}
                    setDashboard={setDashboard}
                    setSelectedIndex={setSelectedIndex}
                />
            </ModalComponent>
            <ModalComponent isOpen={scheduleModal.status} setOpen={() => { }}>
                <div>
                    <ScheduleTestIndex
                        edgeData={scheduleModal.data}
                        setScheduleModal={setScheduleModal}
                        setRedirectTo={setRedirectTo}
                        setTestLogs={setTestLogs}
                        setSelectedIndex={setSelectedIndex}
                        updateQueryParams={updateQueryParams}
                    />
                </div>
            </ModalComponent>
            <ModalComponent isOpen={isOpenDocumentModal.status} setOpen={() => { }}>
                <div style={{ overflow: 'auto' }}>
                    <DocumentEditor edgeData={isOpenDocumentModal.data} setOpen={setOpenDocumentModal} />
                </div>
            </ModalComponent>
            <div className={classes.Header}>
                <div>
                    <Typography content="Edge Devices" />
                    <Formik initialValues={{ customerId: '' }}>
                        {({ errors, touched, values, isValidating, ...props }) => (
                            <Form>
                                {customers.length > 0 && (
                                    <div className={EdgeClasses.DropDnContainer}>
                                        <Dropdown
                                            name="customerId"
                                            options={customers}
                                            onChange={(e) => {
                                                setSelectCustomer(e);
                                                updateQueryParams('customer', e.value);
                                            }}
                                            defaultValue={selectCustomer}
                                        />
                                    </div>
                                )}
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className={classes.ButtonsContainer} style={{ alignItems: 'center' }}>
                    <div style={{ minWidth: '10vw' }}>
                        <DropdownComponent
                            defaultValue={selectedStatus}
                            options={statusOptions}
                            onChange={(e) => {
                                setSelectedStatus(e);
                            }}
                        />
                    </div>
                    <button onClick={() =>
                        setDeviceModal({
                            ...deviceModal,
                            status: true,
                            createDuplicate: false
                        })}
                        className="btn-primary" style={{ marginTop: '0' }}>
                        Add Device
                    </button>
                </div>
            </div>
            <ModalComponent isOpen={infoModal.status} setOpen={(status) => setInfoModal({ ...infoModal, status })}>
                <div>
                    <EdgeInfo infoModal={infoModal} setInfoModal={setInfoModal} selectCustomer={selectCustomer} rotateMQTTPassword={rotateMQTTPassword} rotateAPIKey={rotateAPIKey} handleAPIKeyGenerateSuccess={handleAPIKeyGenerateSuccess} />
                </div>
            </ModalComponent>
            <ModalComponent isOpen={deviceModal.status} setOpen={(status) => { }}>
                <div>
                    <Device
                        setModal={(status) => {
                            setDeviceModal({
                                status,
                                device: {},
                                createDuplicate: false
                            });
                        }}
                        startLoader={startLoader}
                        stopLoader={stopLoader}
                        fetchBids={getData}
                        deviceDetails={deviceModal.device}
                        customerId={selectCustomer.value}
                        customerCode={selectCustomer.customerCode}
                        selectCustomer={selectCustomer}
                        createDuplicate={deviceModal.createDuplicate}
                    />
                </div>
            </ModalComponent>
            {/* <ModalComponent
        isOpen={credentialModal.status}
        setOpen={(status) => {
          setCredentialModal({ status, device: {} });
        }}
      >
        <Credentials
          isOpen={credentialModal.status}
          deviceDetails={credentialModal.device}
          setCredentialModal={setCredentialModal}
          startLoader={startLoader}
          stopLoader={stopLoader}
          fetchBids={getData}
          setUpdateConfirmModal={setUpdateConfirmModal}
        />
      </ModalComponent> */}
            <ModalComponent isOpen={commandModal.status} setOpen={(status) => setCommandModal({ status, device: {} })}>
                <Commands device={commandModal.device} setOpen={(status) => setCommandModal({ status, device: {} })} startLoader={startLoader} stopLoader={stopLoader} />
            </ModalComponent>
            <DeleteModal
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
            ></DeleteModal>
            {/* <DeleteModal
        deletefunction={handleMqttRestart}
        opendeleteModal={updateConfirmModal}
        setOpenDeleteModal={setUpdateConfirmModal}
        title={
          <div style={{ textAlign: "center" }}>
            Do you want to apply change to MQTT Broker?
          </div>
        }
        text={
          "Updating credentials might cause disterpancy with the MQTT connections for few seconds."
        }
      /> */}
            <Table
                head={['Device ID', 'Edge Alias', 'Last Reported', 'License/Instance', 'Code Version', 'Monitoring', 'Action']}
                keys={['deviceId', 'edgeAlias', 'lastReported', 'licenseAndInstance', 'codeVersion', 'alert', 'action']}
                data={data}
                page={page}
                Pagination={true}
                limit={limit}
                handlePageChange={handlePageChange}
                totalCount={totalCount}
            />
        </div>
    );
};

export default Edge;
