import { HttpClient } from '../utils/httpClient';

const PATH = {
    readAll: '/root/in-news',
    create: '/root/in-news',
    delete: '/root/in-news',
};

const ReadAll = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readAll).then(callback).catch(error).finally(next);
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

const Delete = (data, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${data.id}`, { params: data }).then(callback).catch(error).finally(next);
};

export const RootInNewsService = {
    ReadAll,
    Create,
    Delete,
};
