// Standard library imports
import React, { useState } from 'react';
import classes from '../../../../../styles/AllDevices.module.css';
import Typography from '../../../../../components/Typography/Typography';
import { DropdownComponent } from '../../../../../components/Inputs/Input';
import { useLoader } from '../../../../../hooks';

// External library imports
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import CkeEditor from '../../../../../components/CkeEditor';
import moment from 'moment';
import { usToEuCurrencyFormat } from '../../../../../utils/currencyHelper';
import { InvoiceServiceRoot } from '../../../../../services/RootInvoiceService';
import { momentTimeFormater } from '../../../../../utils/timeHelper';

// Css imports
import invoiceClasses from '../index.module.css';

const LANGUAGE_OPTIONS = [
    { label: 'Danish', value: 'da' },
    { label: 'English', value: 'en' },
];

const monthNamesDanish = [
    "januar", "februar", "marts", "april", "maj", "juni",
    "juli", "august", "september", "oktober", "november", "december",
];

const InvoicePreview = ({
    accountTypeId,
    accountType,
    accountTypeValue,
    date,
    revenue,
    revenueEuro,
    setOpen,
    getInvoices
}) => {
    const [startLoader, stopLoader] = useLoader();

    const updatedDate = momentTimeFormater(date).add(2, 'months').endOf('month');

    // Get the day, month, and year
    const day = updatedDate.format('DD');
    const monthDanish = monthNamesDanish[updatedDate.month()]; // Map the month index to Danish month names
    const monthEnglish = updatedDate.format('MMMM');
    const year = updatedDate.format('YYYY');

    // Format the date
    const twoMonthForwardDanish = `${day}.${monthDanish}.${year}`;
    const twoMonthForwardEnglish = `${day}.${monthEnglish}.${year}`.toLowerCase();

    const EMAIL_BODY_DANISH = `<html>
   <body style="font-family: Roboto-Regular,Arial,sans-serif; margin: 0; padding: 10px 0; background: #EDEFF4;">
      <h1 style="color: #1B9F78;"> &nbsp;&nbsp;&nbsp;&nbsp;</h1>
      <div style="width: 100%; margin: auto; max-width: 620px; background: #FFFFFF;border-radius: 32px;">
         <div style="padding: 30px">
            <p style="line-height: 24px; color: #1B1D21; ">${accountTypeValue}</p>
            <br/>
            <br/>
            
            <span style="line-height: 24px; color: #1B1D21;">
            Kære kunde,</span>
            
            <p style="color: #505862; font-size: 15px;">S.C.Nordic A/S har opgjort udligningen af elnettet for ${monthNamesDanish[momentTimeFormater(date).month()]} ${momentTimeFormater(date).format('YYYY')}, og i denne forbindelse bedes I sende os 1 stk. faktura på følgende beløb med betalingsbetingelse ${twoMonthForwardDanish}.</p>
            
            <br/>
            
            <p style="line-height: 24px; color: #1B1D21; ">Udligning ${momentTimeFormater(date).format('YYYY')}.${momentTimeFormater(date).format('MM')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${usToEuCurrencyFormat(revenue)} DKK u/moms</p>
           
            <br/>
            
            <p style="color: #505862; font-size: 15px; margin-bottom: 7px">Best regards / Med venlig hilsen </p>
            <p style="color: #505862; font-size: 15px; margin-bottom: 7px">Flemming Heiberg </p>
            <p style="color: #505862; font-size: 15px; margin-bottom: 7px">Email: regnskab@scnordic.com </p>
            
            <br/>
            
            <figure class="image" style="float: left; width: 50vw; margin-right: 10px;">
                <img src="https://truegreen-uploads.s3.eu-central-1.amazonaws.com/invoiceEmailBody/invoiceEmailBody.png" style="width: 100%; height: auto;">
            </figure>
         </div>
      </div>
   </body>
</html>`;

    const EMAIL_BODY_ENGLISH = `<html>
   <body style="font-family: Roboto-Regular,Arial,sans-serif; margin: 0; padding: 10px 0; background: #EDEFF4;">
      <h1 style="color: #1B9F78;"> &nbsp;&nbsp;&nbsp;&nbsp;</h1>
     <div style="width: 100%; margin: auto; max-width: 620px; background: #FFFFFF;border-radius: 32px;">
         <div style="padding: 30px">
            <p style="line-height: 24px; color: #1B1D21; ">${accountTypeValue}</p>
            
            <br/>
            <br/>
            
            <span style="line-height: 24px; color: #1B1D21;">
            Dear Customer,</span>
            
            <p style="color: #505862; font-size: 15px;">S.C. Nordic A/S has calculated the equalization of the electricity grid for ${momentTimeFormater(date).format('MMMM').toLowerCase()} ${momentTimeFormater(date).format('YYYY')}, where you can sent an invoice with due date on the ${twoMonthForwardEnglish}.</p>
            
            <br/>
            
            <p style="line-height: 24px; color: #1B1D21; ">Equalization ${momentTimeFormater(date).format('YYYY')}.${momentTimeFormater(date).format('MM')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${revenue} DKK +VAT</p>
           
            <br/>
            
            <p style="color: #505862; font-size: 15px; margin-bottom: 7px">Best regards / Med venlig hilsen </p>
            <p style="color: #505862; font-size: 15px; margin-bottom: 7px">Flemming Heiberg </p>
            <p style="color: #505862; font-size: 15px; margin-bottom: 7px">Email: regnskab@scnordic.com </p>
            
            <br/>
            
            <figure class="image" style="float: left; width: 50vw; margin-right: 10px;">
                <img src="https://truegreen-uploads.s3.eu-central-1.amazonaws.com/invoiceEmailBody/invoiceEmailBody.png" style="width: 100%; height: auto;">
            </figure>
         </div>
      </div>
   </body>
</html>`;

    const [selectedLanguage, setSelectedLanguage] = useState(LANGUAGE_OPTIONS[0]);
    const [documentDataDa, setDocumentDataDa] = useState(EMAIL_BODY_DANISH);
    const [documentDataEn, setDocumentDataEn] = useState(EMAIL_BODY_ENGLISH);

    const initialValues = {
        language: LANGUAGE_OPTIONS[0],
        emailBody: EMAIL_BODY_DANISH
    };

    const handleSubmit = (emailType = 'invoiceEmail') => {

        const payload = {
            language: selectedLanguage.value,
            emailBody: selectedLanguage.value === 'da' ? documentDataDa : documentDataEn,
            invoiceType: accountType,
            invoiceTypeValue: accountTypeValue,
            invoiceTypeId: accountTypeId,
            revenue: revenueEuro,
            date: moment(date),
            emailType
        }
        InvoiceServiceRoot.sendInvoice(payload, startLoader, handleCreateSuccess, handleError, stopLoader);
    };

    const handleCreateSuccess = ({ data }) => {
        toast.success('Invoice Sent Successfully');
        setOpen(false);
        getInvoices();
    };

    const handleError = (err) => {
        toast.error(err?.response?.data?.message);
    };

    return (
        <div style={{ paddingBottom: '2vw' }}>
            <Typography content={'Invoice Preview'} />
            <div className={classes.SupportNoteForm}>
                <div style={{ marginTop: '1.5vw' }} >
                    <div className={classes.FieldControl}>
                        <label>Language</label>
                        <DropdownComponent
                            name="language"
                            options={LANGUAGE_OPTIONS}
                            defaultValue={LANGUAGE_OPTIONS[0]}
                            onChange={(e) => {
                                setSelectedLanguage(e);
                            }}
                        />
                    </div>
                </div>
                <div style={{ marginTop: '1vw' }} >
                    <Formik initialValues={initialValues} enableReinitialize onSubmit={() => handleSubmit('invoiceEmail')}>
                        {({ errors, touched, values, isValidating, ...props }) => {
                            return (
                                <Form>
                                    <div className={classes.FieldControl2}>
                                        <label for="emailBody">
                                            Email Body <span className="required">*</span>
                                        </label>
                                        {
                                            selectedLanguage.value === 'da' ?
                                                (<CkeEditor
                                                    name="emailBody"
                                                    onChange={(e) => setDocumentDataDa(e)}
                                                    defaultValue={documentDataDa}
                                                // apiUrl={'root/economics/invoice'}
                                                ></CkeEditor>) :
                                                (
                                                    <CkeEditor
                                                        name="emailBody"
                                                        onChange={(e) => setDocumentDataEn(e)}
                                                        defaultValue={documentDataEn}
                                                    // apiUrl={'root/economics/invoice'}
                                                    ></CkeEditor>
                                                )
                                        }
                                    </div>

                                    <div className={classes.ButtonContainer} style={{ alignItems: 'baseline' }}>
                                        <div className={invoiceClasses.HistoryLink} onClick={() => handleSubmit('creditEmail')}>
                                            <p>Add to Credit Note</p>
                                        </div>
                                        <div>
                                            <button type="button" className="btn-secondary" onClick={() => setOpen(false)}>
                                                Cancel
                                            </button>
                                        </div>
                                        <div>
                                            <button className="btn-primary" type="submit">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default InvoicePreview;
