// Standard Library Imports
import React, { useState, useEffect } from 'react';

// External Library Imports
import { toast } from 'react-toastify';
import moment from 'moment-timezone';

// Internal Module Imports
import Typography from '../../../../components/Typography/Typography';
import DateRangePicker from '../../../../components/Inputs/DateRangePicker/DateRangePicker';
import Table from '../../../../components/Table/Table';
import { momentTimeFormater } from '../../../../utils/timeHelper';
import { RootEconomicsService } from '../../../../services/EconomicsService';
import { useLoader } from '../../../../hooks';
import { ArcherContainer, ArcherElement } from 'react-archer';
import { DownloadAsExcel } from '../../../../utils/downloadAsExcel';
import DownloadButton from '../../../../components/Buttons/DownloadButton';
import { DropdownComponent } from '../../../../components/Inputs/Input';

// CSS Imports
import classes from '../Economics.module.css';
import { AdminService } from '../../../../services/AdminService';

const getAdminOptions = (admins = []) => {
    const options = [{ label: 'All', value: 'All' }];
    admins.forEach((c) => options.push({ label: c.name, value: c._id }));
    return options;
};

const getCustomerOptions = (customers = []) => {
    const options = [{ label: 'All', value: 'All' }];
    customers.forEach((c) => options.push({ label: c.name, value: c._id }));
    return options;
};

const viewOptions = [
    { label: 'Market', value: 'market' },
    { label: 'Customer', value: 'customer' },
];

const Ledger = ({ admins: adminsData = [], queryParamsData, updateQueryParams }) => {
    const [startLoader, stopLoader] = useLoader();
    const date = new Date();
    const [dateRange, setDateRange] = useState(
        queryParamsData.date
            ? {
                  startDate: new Date(JSON.parse(queryParamsData.date).startDate),
                  endDate: new Date(JSON.parse(queryParamsData.date).endDate),
              }
            : {
                  startDate: new Date(date.getFullYear(), date.getMonth(), 1),
                  endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
              }
    );
    const [data, setData] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [admin, setAdmin] = useState({});
    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState(queryParamsData.customer ? JSON.parse(queryParamsData.customer) : {});
    const [selectedView, setSelectedView] = useState(queryParamsData.view ? { value: queryParamsData.view } : viewOptions[0]);
    const [overalRev, setoverAllrev] = useState({
        total: 0,
        sc: 0,
        partner: 0,
        enabler: 0,
        customer: 0,
    });

    useEffect(() => {
        updateQueryParams('date', JSON.stringify(dateRange));
    }, [dateRange]);

    useEffect(() => {
        if (Array.isArray(adminsData) && adminsData.length) {
            const adminOptions = getAdminOptions(adminsData);
            setAdmins(adminOptions);
            setAdmin(queryParamsData.admin ? JSON.parse(queryParamsData.admin) : adminOptions[0]);
        } else {
            setAdmins([]);
            setAdmin({});
        }
    }, [JSON.stringify(adminsData)]);

    useEffect(() => {
        if (!admin.value) return;
        if (admin.value !== 'All') fetchAdminCustomers();
        else {
            const options = getCustomerOptions([]);
            setCustomers(options);
            setCustomer(options[0]);
        }
    }, [admin]);

    useEffect(() => {
        fetchLedgerData();
    }, [customer]);

    const fetchAdminCustomers = () => {
        AdminService.GetMarketParticipatingCustomers(
            admin.value,
            () => startLoader('getLedger'),
            handleCustomersSuccess,
            handleError,
            () => stopLoader('getLedger')
        );
    };

    const handleCustomersSuccess = ({ data }) => {
        const list = data?.data;
        if (Array.isArray(list)) {
            const options = getCustomerOptions(list);
            setCustomers(options);
        }
    };

    const fetchLedgerData = () => {
        if (admin.value && customer.value) {
            const params = {
                startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
                endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
                adminId: admin.value,
                customerId: customer.value,
                view: selectedView?.value,
            };
            RootEconomicsService.ReadLedger(
                params,
                () => startLoader('getLedger'),
                handleFetchSuccess,
                handleError,
                () => stopLoader('getLedger')
            );
        }
    };

    const handleFetchSuccess = ({ data }) => {
        const revenueData = data?.data?.revenueData;
        const overalRevenue = data?.data?.overAllRevenue || {};
        if (revenueData && Array.isArray(revenueData)) {
            const processed = revenueData.map((item) => {
                item.totalNew = item.totalRevenue.toLocaleString('da-DK');
                item.scNew = item.scTotal.toLocaleString('da-DK');
                item.partnerNew = item.partnerTotal.toLocaleString('da-DK');
                item.enablerNew = item.enablerTotal.toLocaleString('da-DK');
                item.customerNew = item.customerTotal.toLocaleString('da-DK');
                return item;
            });
            setData(processed);
        } else {
            setData([]);
        }
        setoverAllrev(overalRevenue);
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    const handleDataExport = () => {
        const excelData = data.map((item) => ({
            a: item.name,
            b: item.partner,
            c: item.totalRevenue,
            d: item.scTotal,
            e: item.partnerTotal,
            f: item.enablerTotal,
            g: item.customerTotal,
        }));
        excelData.push({
            a: 'Total',
            b: '',
            c: overalRev.total,
            d: overalRev.sc,
            e: overalRev.partner,
            f: overalRev.enabler,
            g: overalRev.customer,
        });
        if (excelData.length > 1) {
            DownloadAsExcel(excelData, `Ledger-Export`, ['Customer', 'Partner', 'Total (€)', 'S.C. Share (€)', 'Partner Share (€)', 'Enabler Share (€)', 'Customer Share (€)']);
        } else {
            handleError({ response: { data: { message: 'No data to export!' } } });
        }
    };

    return (
        <div className={classes.Details}>
            <div className={classes.FilterContainer}>
                <div className={classes.ButtonsContainer}>
                    <Typography content="Ledger" />
                    <div className={classes.DropDnContainer}>
                        <label>Admin</label>
                        <DropdownComponent
                            options={admins}
                            defaultValue={admin}
                            onChange={(e) => {
                                setAdmin(e);
                                updateQueryParams('admin', JSON.stringify(e));
                            }}
                        />
                    </div>
                    <div className={classes.DropDnContainer}>
                        <label>Customer</label>
                        <DropdownComponent
                            options={customers}
                            defaultValue={customer}
                            onChange={(e) => {
                                setCustomer(e);
                                updateQueryParams('customer', JSON.stringify(e));
                            }}
                        />
                    </div>
                </div>
                <div className={classes.DatePickers}>
                    <div className={classes.DropDnContainer}>
                        <label>View</label>
                        <DropdownComponent
                            options={viewOptions}
                            defaultValue={selectedView}
                            onChange={(e) => {
                                setSelectedView(e);
                                updateQueryParams('view', e.value);
                            }}
                        />
                    </div>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className={classes.DateLabels}>Start Date</div>
                            <div className={classes.DateLabels} style={{ marginRight: '2.604vw' }}>
                                End Date
                            </div>
                        </div>
                        <DateRangePicker
                            startDate={dateRange.startDate}
                            endDate={dateRange.endDate}
                            onChange={(date) =>
                                setDateRange({
                                    startDate: new Date(date.startDate),
                                    endDate: new Date(date.endDate),
                                })
                            }
                        />
                    </div>
                    <div className={classes.SubmitButton} onClick={fetchLedgerData}>
                        <button className="btn-primary">Submit</button>
                    </div>
                    <DownloadButton size={'medium'} onClick={handleDataExport} />
                </div>
            </div>
            <div className={classes.OverAllParent}>
                <ArcherContainer
                    endShape={{
                        arrow: {
                            arrowLength: 0,
                            arrowThickness: 0,
                        },
                    }}
                >
                    <div className={classes.OverAllWrapper}>
                        <div className={classes.Center}>
                            <ArcherElement
                                id="start"
                                relations={[
                                    {
                                        targetId: 'center',
                                        targetAnchor: 'top',
                                        sourceAnchor: 'bottom',
                                        style: {
                                            strokeColor: 'var(--color-primary)',
                                            strokeWidth: 2,
                                        },
                                    },
                                ]}
                            >
                                <div className={classes.RevenueCardTotal}>
                                    <p>Total</p>
                                    <span>{Math.round(overalRev.total)?.toLocaleString('da-DK')} €</span>
                                </div>
                            </ArcherElement>
                        </div>

                        <ArcherElement
                            id={'center'}
                            relations={[
                                {
                                    targetId: 'sc',
                                    targetAnchor: 'top',
                                    sourceAnchor: 'bottom',
                                    style: {
                                        strokeColor: 'var(--color-primary)',
                                        strokeWidth: 2,
                                    },
                                },
                                {
                                    targetId: 'partner',
                                    targetAnchor: 'top',
                                    sourceAnchor: 'bottom',
                                    style: {
                                        strokeColor: 'var(--color-primary)',
                                        strokeWidth: 2,
                                    },
                                },
                                {
                                    targetId: 'enabler',
                                    targetAnchor: 'top',
                                    sourceAnchor: 'bottom',
                                    style: {
                                        strokeColor: 'var(--color-primary)',
                                        strokeWidth: 2,
                                    },
                                },
                                {
                                    targetId: 'customer',
                                    targetAnchor: 'top',
                                    sourceAnchor: 'bottom',
                                    style: {
                                        strokeColor: 'var(--color-primary)',
                                        strokeWidth: 2,
                                    },
                                },
                            ]}
                        >
                            <div className={classes.Center}></div>
                        </ArcherElement>
                        <div className={classes.OverRevContainer}>
                            <ArcherElement id={'sc'}>
                                <div className={classes.RevenueCard}>
                                    <p>S.C.</p>
                                    <span>{Math.round(overalRev.sc)?.toLocaleString('da-DK')} €</span>
                                </div>
                            </ArcherElement>
                            <ArcherElement id={'partner'}>
                                <div className={classes.RevenueCard}>
                                    <p>Partner</p>
                                    <span>{Math.round(overalRev.partner)?.toLocaleString('da-DK')} €</span>
                                </div>
                            </ArcherElement>
                            <ArcherElement id={'enabler'}>
                                <div className={classes.RevenueCard}>
                                    <p>Enabler</p>
                                    <span>{Math.round(overalRev.enabler)?.toLocaleString('da-DK')} €</span>
                                </div>
                            </ArcherElement>
                            <ArcherElement id={'customer'}>
                                <div className={classes.RevenueCard}>
                                    <p>Customer</p>
                                    <span>{Math.round(overalRev.customer)?.toLocaleString('da-DK')} €</span>
                                </div>
                            </ArcherElement>
                        </div>
                    </div>
                </ArcherContainer>
            </div>
            <Table
                data={data}
                head={['Customer', 'Partner', 'Total (€)', 'SC Share (€)', 'Partner Share (€)', 'Enablers Share (€)', 'Customer Share (€)']}
                keys={['name', 'partner', 'totalNew', 'scNew', 'partnerNew', 'enablerNew', 'customerNew']}
            />
        </div>
    );
};

export default Ledger;
