import { HttpClient } from '../utils/httpClient';

const PATH = {
    read_all: '/root/eic-codes',
    update: '/root/eic-codes',
    delete: '/root/eic-codes',
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.read_all}`, payload).then(callback).catch(error).finally(next);
};

const ReadAll = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read_all).then(callback).catch(error).finally(next);
};

const Delete = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`).then(callback).catch(error).finally(next);
};

const Update = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.put(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

export const EICCodeService = {
    ReadAll,
    Delete,
    Create,
    Update,
};
