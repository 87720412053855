// External library imports
import * as yup from 'yup';
import _ from 'lodash';
// Internal module imports
import { ERRORS } from '../../utils/validationHelper';
import { generateHourlyTimestamps } from '../../utils/dateHelper';
import moment from 'moment';
import { AFRR_MARKET_TYPES } from '../../constants';

const checkMultiples = (sum) => {
    const remainder = sum % 0.1;
    const tolerance = 0.00001;
    return remainder <= tolerance || Math.abs(sum - Math.round(sum * 10) / 10) <= tolerance;
};

const capacity = (pairs, timestampsForWholeDay, approvedCapacity) => {
    let temp = {};
    const capacityValidation = {};

    timestampsForWholeDay.forEach(
        (hour) =>
            (capacityValidation[hour] = yup
                .number()
                .typeError(ERRORS.number)
                .min(0)
                .required(ERRORS.required)
                .test('approved-capacity-test', 'Capacity should be less than or equal to approved capacity', function (value, context) {
                    const { path } = context;
                    const instanceId = path.split('.')[1];
                    if (value > _.get(approvedCapacity, [instanceId], 0)) {
                        return false;
                    }
                    return true;
                })
                .test('modulo-test', 'Sum of capacity will be multiple of 0.1', function (value, context) {
                    const capacity = context?.from[1].value;
                    let sum = 0;
                    for (let key in capacity) {
                        sum += Number(capacity[key][hour]);
                    }
                    return checkMultiples(sum);
                }))
    );

    pairs.map((e) => {
        temp[e.instanceId] = yup.object().shape(capacityValidation);
    });

    return temp;
};

export const CreateBundleBidValidation = (pairs, date, market, approvedCapacity) => {
    const timestampsForWholeDay = generateHourlyTimestamps(date, moment(date).add(1, 'day'));
    const priceValidation = {};
    timestampsForWholeDay.forEach((hour) => (priceValidation[hour] = yup.number().typeError(ERRORS.number).min(0).required(ERRORS.required)));

    return yup.object().shape({
        capacity: yup.object().shape(capacity(pairs, timestampsForWholeDay, approvedCapacity)),
        price: yup.object().shape(priceValidation),
        ...(AFRR_MARKET_TYPES.includes(market)
            ? {
                  regulationPrice: yup.object().shape(priceValidation),
              }
            : { reserveBidIdentification: yup.string().required(ERRORS.required) }),

        bidType: yup.string().required(ERRORS.required),
    });
};

const capacityDMinus1 = (pairs, bidStatus = {}, approvedCapacity = {}) => {
    let temp = {};
    pairs.map((e) => {
        temp[e.instanceId] = yup.array().of(
            yup
                .mixed()
                .test({
                    name: 'isNotNull',
                    message: ERRORS.required,
                    test: (value, c) => {
                        const combinedCap = _.get(bidStatus, [e.instanceId, c.options.index, 'capacity'], 0) + value;
                        if (value === null || (value && combinedCap <= _.get(approvedCapacity, [e.instanceId], 0)) || value == 0) {
                            return true;
                        } else if (combinedCap > _.get(approvedCapacity, [e.instanceId], 0)) {
                            return c.createError({
                                message: `Capacity should be less than or equal to approved capacity.`,
                                path: c.path,
                            });
                        } else {
                            return false;
                        }
                    },
                })
                .test('modulo-test', 'Sum of capacity will be multiple of 0.1', function (value, context) {
                    const capacity = context?.from[0].value;
                    const i = context?.options.index;
                    let sum = 0;
                    for (let key in capacity) {
                        if (capacity[key][i] > 0) {
                            sum += Number(capacity[key][i]);
                        }
                    }
                    return checkMultiples(sum);
                })
        );
    });
    return temp;
};

export const CreateDMinusBundleBidValidation = (instances, bidStatus, approvedCapacity) => {
    return yup.object().shape({
        capacity: yup.object().shape(capacityDMinus1(instances, bidStatus, approvedCapacity)),
        price: yup.array().of(
            yup.mixed().test({
                name: 'isNotNull',
                message: ERRORS.required,
                test: (value) => {
                    if (value === null || value >= 0) {
                        return true;
                    } else {
                        return false;
                    }
                },
            })
        ),
    });
};

export const ResultMessageValidator = () => {
    const wrapper = {
        reserveBidIdentification: yup.string().required(ERRORS.required),
        primaryResultMessageId: yup.number().optional(),
        secondaryResultMessageId: yup.number().optional(),
    };

    return yup.object().shape(wrapper);
};
