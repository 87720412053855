// Internal module imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    getInvoices: '/root/invoice/sent',
    create: '/root/invoice',
    get: '/root/invoice',
};

const getInvoices = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.getInvoices, { params }).then(callback).catch(error).finally(next);
};

const sendInvoice = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

const GetRevenue = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.get}`, { params }).then(callback).catch(error).finally(next);
};

export const InvoiceServiceRoot = {
    GetRevenue,
    sendInvoice,
    getInvoices
};
