import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';

const validateNumber = () => yup.number().typeError(ERRORS.number).required(ERRORS.required);

const capacityValidate = (approvalCapacity) =>
    yup.number().max(approvalCapacity, 'Capacity should be less than or equal to approved capacity').typeError(ERRORS.number).required(ERRORS.required);

export const editBidValidation = (approvalCapacity) => {
    return yup.object().shape({
        capacity: yup.array().of(capacityValidate(approvalCapacity)),
        price: yup.array().of(validateNumber()),
        reserveBidIdentification: yup.string().required(ERRORS.required),
    });
};
