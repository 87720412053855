// Standard library imports
import React, { useState } from 'react';
// External library imports
import _ from 'lodash';
import { Form, Formik } from 'formik';

// CSS Imports
import classes from '../../../styles/CapacityContent.module.css';
import Checkbox from '../../../components/Inputs/Checkbox/Checkbox';
import bidClasses from '../index.module.css';
import Typography from '../../../components/Typography/Typography';

export const OutOfMarket = ({ data, setOpen, setOutOfMarketHours, outOfMarketHours = [] }) => {
    const length = Math.floor(_.get(data, ['bidStatus'], []).length / 3);

    const [hourData, setHourData] = useState(outOfMarketHours);

    const getCheckedhours = (value, productName) => {
        if (value) {
            setHourData([...hourData, productName]);
        } else {
            setHourData(hourData.filter((e) => e !== productName));
        }
    };

    const handleSubmit = () => {
        setOutOfMarketHours(hourData);
        setOpen({ status: false });
    };

    return (
        <div>
            <div className={`${classes.CapacityContent} ${bidClasses.InfoModalContainer}`}>
                <div className={bidClasses.InfoModalHeading}>
                    <Typography content={'Select Hours'} size={14} />
                </div>
                <Formik initialValues={{}} onSubmit={handleSubmit}>
                    {({ errors, touched, values, isValidating, ...props }) => (
                        <Form>
                            <div className={classes.Content}>
                                <div className={classes.ContainerStart}>
                                    {[0, 1, 2].map((n) => (
                                        <table>
                                            <tr>
                                                <th>Product Name</th>
                                            </tr>
                                            {Array.from({ length: length }, (_, i) => i + n * length).map((key) =>
                                                key <= 23 ? (
                                                    <tr>
                                                        <td style={{ display: 'flex', gap: '0.5vw' }}>
                                                            <Checkbox
                                                                checked={hourData.includes(_.get(data, ['bidStatus', key, 'productName'])) ? true : false}
                                                                onChange={(e) => getCheckedhours(e, _.get(data, ['bidStatus', key, 'productName']))}
                                                            />{' '}
                                                            {_.get(data, ['bidStatus', key, 'productName'], '--')}
                                                        </td>
                                                    </tr>
                                                ) : null
                                            )}
                                        </table>
                                    ))}
                                </div>
                            </div>

                            <div className={bidClasses.ButtonContainer}>
                                <div>
                                    <button type="button" className="btn-secondary" onClick={() => setOpen({ status: false })}>
                                        Cancel
                                    </button>
                                </div>
                                <div>
                                    <button type="submit" className="btn-primary">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};
