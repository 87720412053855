import { HttpClient } from '../utils/httpClient';
import { formatStartDateStringMoment, formatEndDateStringMoment } from '../utils/timeHelper';
const PATH = {
    getBids: '/admin/bidding',
    getLastBid: '/admin/bidding/last-bid',
    postBid: '/admin/bidding/post-bid',
    fetchBidStatus: '/admin/bidding/fetch-status',
    addResultId: '/admin/bidding/addResultId',
    deleteBid: '/admin/bidding/bid',
    panic: '/admin/bidding/panic',
    create: '/admin/bidding',
    maintenanceHours: '/admin/bidding/maintenance-hours',
    marketEnabledGroups: 'admin/bidding/market-enabled-groups',
    UpdateSecondaryBid: 'admin/bidding/secondary-bid',
    bidRequest: '/admin/bidding/bid-request',
    deleteBidRequest: '/admin/bidding/bid-request',
    deleteMaintenanceHours: '/admin/bidding/maintenance-hours',
    bidsExport: '/admin/bidding/export',
    totalCapacity: '/admin/bidding/total-capacity',
    addNote: '/admin/bidding/add-note',
    getNotes: '/admin/bidding/get-note',
    deleteNote: '/admin/bidding/delete-note',
    getLogs: '/admin/bidding/logs',
    solecast: '/admin/bidding/solecast',
    approvedCapacity: '/admin/bidding/approved-capacity',
};
export const formatedEndDate = (date) => {
    return `${formatEndDateStringMoment(date)}`;
};
export const formatedStartDate = (date) => {
    return `${formatStartDateStringMoment(date)}`;
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.create}`, payload).then(callback).catch(error).finally(next);
};

const PostBid = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.postBid}/${id}`, payload).then(callback).catch(error).finally(next);
};

const GetBids = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.getBids, { params }).then(callback).catch(error).finally(next);
};

const GetLastBid = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getLastBid}`, { params }).then(callback).catch(error).finally(next);
};
const FecthBidStatus = (id, start, callback, error, next) => {
    start();
    return HttpClient.put(`${PATH.fetchBidStatus}/${id}`).then(callback).catch(error).finally(next);
};

const Delete = (id, params, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.deleteBid}/${id}`, { params }).then(callback).catch(error).finally(next);
};

const AddResultId = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.addResultId}/${id}`, payload).then(callback).catch(error).finally(next);
};

const UpdateSecondaryBid = (id, data, start, callback, error, next) => {
    start();
    return HttpClient.put(`${PATH.UpdateSecondaryBid}/${id}`, data).then(callback).catch(error).finally(next);
};

const GetMarketEnabledGroups = (market, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.marketEnabledGroups}/${market}`).then(callback).catch(error).finally(next);
};

const GetBidRequestForADate = (params, groupId, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.bidRequest}/${groupId}`, { params }).then(callback).catch(error).finally(next);
};

const GetBidRequests = (params = {}, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.bidRequest}`, { params }).then(callback).catch(error).finally(next);
};

const DeleteBidRequest = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.deleteBidRequest}/${id}`).then(callback).catch(error).finally(next);
};

const GetMaintenanceHours = (params = {}, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.maintenanceHours}`, { params }).then(callback).catch(error).finally(next);
};

const DeleteMaintenanceHours = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.deleteMaintenanceHours}/${id}`).then(callback).catch(error).finally(next);
};

const GetMaintenanceHoursForADate = (params, groupId, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.maintenanceHours}/${groupId}`, { params }).then(callback).catch(error).finally(next);
};

const BidsExport = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.bidsExport}`, { params }).then(callback).catch(error).finally(next);
};

const BidCapacity = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.totalCapacity}`, { params }).then(callback).catch(error).finally(next);
};

const UpdatePanicHours = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.put(`${PATH.panic}/${id}`, payload).then(callback).catch(error).finally(next);
};

const CreateNote = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.put(`${PATH.addNote}/${id}`, payload).then(callback).catch(error).finally(next);
};

const GetNote = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getNotes}/${id}`).then(callback).catch(error).finally(next);
};
const DeleteNote = (id, params, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.deleteNote}/${id}`, { params }).then(callback).catch(error).finally(next);
};
const GetLogs = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getLogs}/${id}`).then(callback).catch(error).finally(next);
};

const GetSolcastData = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.solecast}`, { params }).then(callback).catch(error).finally(next);
};

const GetApprovedCapacity = (instanceId, params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.approvedCapacity}/${instanceId}`, { params }).then(callback).catch(error).finally(next);
};

export const AdminBiddingService = {
    GetBids,
    Create,
    Delete,
    GetLastBid,
    PostBid,
    FecthBidStatus,
    AddResultId,
    UpdateSecondaryBid,
    GetMarketEnabledGroups,
    GetBidRequestForADate,
    GetBidRequests,
    DeleteBidRequest,
    GetMaintenanceHours,
    DeleteMaintenanceHours,
    GetMaintenanceHoursForADate,
    BidsExport,
    BidCapacity,
    UpdatePanicHours,
    CreateNote,
    GetNote,
    DeleteNote,
    GetLogs,
    GetSolcastData,
    GetApprovedCapacity,
};
