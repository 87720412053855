// Standard library import
import React, { useEffect, useState } from 'react';

// External library import
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { UilTrash, UilPen, UilDocumentInfo, UilInfoCircle } from '@iconscout/react-unicons';

// Internal module import
import ModalComponent from '../../components/ModalComponent/ModalComponent';
import Table from '../../components/Table/Table';
import Typography from '../../components/Typography/Typography';
import { CustomerService } from '../../services/CustomerService';
import { useLoader } from '../../hooks';
import SystemForm from './ModalComponent/SystemForm';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import { momentTimeFormater } from '../../utils/timeHelper';
import CustomTooltip from '../../components/CustomToolTip/CustomTooltip';
import Dropdown from '../../components/Inputs/Dropdown';
import DocumentEditor from './ModalComponent/DocumentEditor';
import { SystemServices } from '../../services/SystemServices';
import SystemInfo from './ModalComponent/systemInfo';

// CSS Imports
import classes from '../../styles/Bids.module.css';
import EdgeClasses from './Edge.module.css';

const System = ({ updateQueryParams, queryParamsData = {} }) => {
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(queryParamsData.systemModal ? queryParamsData.systemModal : false);
    const [editModal, setEditModal] = useState(false);
    const [modalData, setModalData] = useState({});
    const [deleteModal, setDeleteModal] = useState(false);
    const [skip, setSkip] = useState(0);
    const [limit] = useState(15);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [customers, setCustomers] = useState([]);
    const [selectCustomer, setSelectCustomer] = useState(
        queryParamsData?.systemCustomer
            ? queryParamsData.systemCustomer
            : {
                value: '',
                label: '',
                customerCode: '',
            }
    );
    const [isOpenDocumentModal, setOpenDocumentModal] = useState({
        status: false,
        data: {},
    });

    const [infoModal, setInfoModal] = useState({
        status: false,
        data: {},
    });

    useEffect(() => {
        updateQueryParams('systemModal', modal);
    }, [modal]);

    useEffect(() => {
        if (selectCustomer.value) {
            getData();
        }
    }, [skip, limit, selectCustomer]);

    useEffect(() => {
        fetchCustomers();
    }, []);

    const fetchCustomers = () => {
        CustomerService.ReadAll({ isActive: true }, startLoader, customerSuccessHandler, handleError, stopLoader);
    };

    const matchQueryParams = (data = [], match = {}) => {
        for (let item of data) {
            if (item.value === match?.value) {
                return true;
            }
        }
        return false;
    };

    const customerSuccessHandler = ({ data }) => {
        let customers = data.data;
        let temp = [];
        customers.forEach((element) => {
            temp.push({
                value: element._id,
                label: element.name,
                customerCode: element.customerCode,
            });
        });
        setCustomers(temp);
        if (temp.length) {
            const queryMatched = matchQueryParams(temp, queryParamsData.systemCustomer);
            setSelectCustomer(queryMatched ? queryParamsData.systemCustomer : temp[0]);
            !queryMatched && updateQueryParams('systemCustomer', JSON.stringify(temp[0]));
        } else {
            setSelectCustomer({
                value: '',
                label: '',
                customerCode: '',
            });
        }
    };

    const getData = () => {
        const params = { limit, skip, customerId: selectCustomer.value };
        SystemServices.GetAll(params, startLoader, handleGetDataSuccess, handleError, stopLoader);
    };

    const handleGetDataSuccess = ({ data }) => {
        const processedData = data?.data[0]?.data.map((item) => ({
            ...item,
            hostName: item.hostName,
            vmName: item.vmName,
            alias: item.alias ? item.alias : '--',
            createdAt: momentTimeFormater(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
            teamViewerStatus: (
                <div className={EdgeClasses.DataMonitoringContainer}>
                    {item.isTeamViewerOnline ? <div className={EdgeClasses.AccessNotBlocked}></div> : <div className={EdgeClasses.AccessBlocked}></div>}
                </div>
            ),
            action: (
                <div
                    style={{
                        display: 'flex',
                        gridColumnGap: '1vw',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CustomTooltip content={'Edit Details'}>
                        <div>
                            <UilPen
                                size={'1.2vw'}
                                style={{ color: 'var(--color-primary)' }}
                                onClick={() => {
                                    setModal(true);
                                    setEditModal(true);
                                    setModalData(item);
                                }}
                            />
                        </div>
                    </CustomTooltip>

                    <CustomTooltip content={'Info'}>
                        <div>
                            <UilInfoCircle
                                size={'1.2vw'}
                                style={{ color: 'var(--color-primary)' }}
                                onClick={() =>
                                    setInfoModal({
                                        status: true,
                                        data: item,
                                    })
                                }
                            />
                        </div>
                    </CustomTooltip>

                    <CustomTooltip content={'Delete'}>
                        <div>
                            <UilTrash
                                size={'1.2vw'}
                                style={{ color: 'var(--color-primary)' }}
                                onClick={() => {
                                    setDeleteModal(true);
                                    setModalData(item);
                                }}
                            />
                        </div>
                    </CustomTooltip>

                    <CustomTooltip content={'Document'}>
                        <div>
                            <UilDocumentInfo
                                size={'1.2vw'}
                                style={{ color: 'var(--color-primary)' }}
                                onClick={() => {
                                    setOpenDocumentModal({ status: true, data: item });
                                }}
                            />
                        </div>
                    </CustomTooltip>
                </div>
            ),
        }));
        setData(processedData);
        setTotalCount(data?.data[0]?.count[0].count);
    };

    const handlePageChange = (pageno) => {
        setSkip(limit * pageno);
        setPage(pageno);
    };

    const handleDelete = () => {
        SystemServices.Delete(modalData._id, startLoader, handleDeleteSuccess, handleError, stopLoader);
    };

    const handleDeleteSuccess = () => {
        getData();
        toast.success('System Deleted');
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    return (
        <div className={classes.Bids}>
            <ModalComponent isOpen={infoModal.status} setOpen={() => setInfoModal({ status: false, data: {} })}>
                <div style={{ overflow: 'auto' }}>
                    <SystemInfo data={infoModal.data} setOpen={setInfoModal} />
                </div>
            </ModalComponent>
            <ModalComponent isOpen={isOpenDocumentModal.status}>
                <div style={{ overflow: 'auto' }}>
                    <DocumentEditor edgeData={isOpenDocumentModal.data} setOpen={setOpenDocumentModal} />
                </div>
            </ModalComponent>
            <div className={classes.Header}>
                <div>
                    <Typography content="Systems" />
                    <Formik initialValues={{ customerId: '' }}>
                        {({ errors, touched, values, isValidating, ...props }) => (
                            <Form>
                                {customers.length > 0 && (
                                    <div className={EdgeClasses.DropDnContainer}>
                                        <Dropdown
                                            name="customerId"
                                            options={customers}
                                            onChange={(e) => {
                                                setSelectCustomer(e);
                                                updateQueryParams('systemCustomer', JSON.stringify(e));
                                            }}
                                            defaultValue={queryParamsData.systemCustomer ? queryParamsData.systemCustomer : selectCustomer}
                                        />
                                    </div>
                                )}
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className={classes.ButtonsContainer}>
                    <div>
                        <button
                            onClick={() => {
                                setModal(true);
                                setEditModal(false);
                                setModalData({});
                            }}
                            className="btn-primary"
                        >
                            Add System
                        </button>
                    </div>
                </div>
            </div>
            <ModalComponent isOpen={modal} setOpen={setModal} customerId={selectCustomer.value}>
                <SystemForm
                    setModal={setModal}
                    editModal={editModal}
                    modalData={modalData}
                    startLoader={startLoader}
                    stopLoader={stopLoader}
                    customer={selectCustomer}
                    getData={getData}
                />
            </ModalComponent>
            <DeleteModal deletefunction={handleDelete} opendeleteModal={deleteModal} setOpenDeleteModal={(e) => setDeleteModal(e)}></DeleteModal>
            <Table
                head={['Host', 'VM', 'Alias', 'TeamViewer Status', 'Created At', 'Action']}
                keys={['hostName', 'vmName', 'alias', 'teamViewerStatus', 'createdAt', 'action']}
                data={data}
                page={page}
                Pagination={true}
                limit={limit}
                handlePageChange={handlePageChange}
                totalCount={totalCount}
            />
        </div>
    );
};

export default System;
