// Standard library imports
import React, { useEffect, useState } from 'react';

// External library imports
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { UilTrash, UilPen } from '@iconscout/react-unicons';

// Internal module imports
import Typography from '../../../../components/Typography/Typography';
import Table from '../../../../components/Table/Table';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import { Input, ToggleButton } from '../../../../components/Inputs/Input';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import { useLoader } from '../../../../hooks/use-loader.hook';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import { EICCodeCreate } from '../../../../validations/SystemTools/EICCodesValidation';
import { EICCodeService } from '../../../../services/EICCodeService';

// Css imports
import classes from '../../../../styles/AllDevices.module.css';
import GlobalConfigClasses from '../index.module.css';
import signalClasses from '../index.module.css';

const EICCodes = () => {
    const [startLoader, stopLoader] = useLoader();
    const [tableData, setTableData] = useState([]);
    const [addModal, setAddModal] = useState({
        status: false,
        data: {},
    });
    const [deleteModal, setDeleteModal] = useState({ status: false, id: null });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        EICCodeService.ReadAll(
            () => startLoader('getEICCodes'),
            handleSuccess,
            handleError,
            () => stopLoader('getEICCodes')
        );
    };

    const handleSuccess = ({ data }) => {
        const processedData = data?.data.map((key, i) => ({
            's.no': i + 1,
            name: key.name,
            EICCode: key.EICCode ? key.EICCode : '--',
            monitoring: (
                <div className={signalClasses.DataMonitoringContainer}>
                    {!!key.monitoring ? <div className={signalClasses.AccessNotBlocked}></div> : <div className={signalClasses.AccessBlocked}></div>}
                </div>
            ),
            action: (
                <div className={GlobalConfigClasses.ActionContainer} style={{ justifyContent: 'center' }}>
                    <CustomTooltip content={'Edit EIC code'}>
                        <div onClick={() => setAddModal({ status: true, data: key })}>
                            <UilPen size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </div>
                    </CustomTooltip>
                    <CustomTooltip content={'Delete EIC code'}>
                        <div onClick={() => setDeleteModal({ status: true, id: key._id })}>
                            <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </div>
                    </CustomTooltip>
                </div>
            ),
        }));
        setTableData(processedData);
    };

    const handleSubmit = (values) => {
        if (addModal.data?._id) {
            delete values.EICCode;
            EICCodeService.Update(
                addModal.data?._id,
                values,
                () => startLoader('updateEICCode'),
                () => handleCreateSuccess('updated'),
                handleError,
                () => stopLoader('updateEICCode')
            );
        } else {
            EICCodeService.Create(
                values,
                () => startLoader('createdEICCode'),
                () => handleCreateSuccess('created'),
                handleError,
                () => stopLoader('createdEICCode')
            );
        }
    };

    const handleCreateSuccess = (type) => {
        fetchData();
        toast.success(`EIC code ${type} sucessfully`);
        setAddModal({ status: false, data: {} });
    };

    const handleDelete = () => {
        if (deleteModal.id) {
            EICCodeService.Delete(
                deleteModal.id,
                startLoader,
                () => {
                    fetchData();
                    toast.success('EIC code deleted sucessfully');
                },
                handleError,
                stopLoader
            );
        }
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div className={classes.AllDevices}>
            <ModalComponent
                isOpen={addModal.status}
                setOpen={(e) => {
                    setAddModal({ status: false, data: {} });
                }}
            >
                <Typography content={addModal.data?._id ? 'Edit EIC Code' : 'Add EIC Code'} />
                <div className={classes.SupportNoteForm} style={{ width: '35vw' }}>
                    <Formik
                        initialValues={{
                            name: addModal.data?.name ? addModal.data?.name : '',
                            EICCode: addModal.data?.EICCode ? addModal.data?.EICCode : '',
                            monitoring: addModal.data?.monitoring ? addModal.data?.monitoring : false,
                        }}
                        enableReinitialize
                        validationSchema={EICCodeCreate}
                        onSubmit={(val) => {
                            handleSubmit(val);
                        }}
                    >
                        {({ errors, touched, values, isValidating, ...props }) => (
                            <Form>
                                <div>
                                    <div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2}>
                                                <label for="name">
                                                    Name<span className="required">*</span>
                                                </label>
                                                <Input name="name" id="name" values={values} style={{ marginTop: '0' }} />
                                            </div>
                                            <div className={classes.FieldControl2}>
                                                <label for="EICCode">
                                                    EIC Code <span className="required">*</span>
                                                </label>
                                                <Input
                                                    name="EICCode"
                                                    id="EICCode"
                                                    values={values}
                                                    disabled={addModal.data?._id ? true : false}
                                                    style={{ marginTop: '0' }}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2}>
                                                <label>Monitoring</label>
                                                <ToggleButton
                                                    label=""
                                                    name="monitoring"
                                                    style={{ marginTop: '0', gridColumnGap: '0' }}
                                                    values={values}
                                                />
                                            </div>
                                        </div>

                                        <div className={classes.ButtonContainer}>
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn-secondary"
                                                    onClick={(e) => {
                                                        setAddModal({ status: false, data: {} });
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            <div>
                                                <button className="btn-primary" type="submit">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </ModalComponent>
            <DeleteModal deletefunction={handleDelete} opendeleteModal={deleteModal.status} setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })} />
            <div className={classes.Header}>
                <div>
                    <Typography content="EIC Codes" />
                    <div className={classes.TableCount}>
                        Total Count :<span>{tableData.length}</span>
                    </div>
                    <div>
                        <button type="submit" className="btn-primary" style={{ marginTop: '0' }} onClick={() => setAddModal({ status: true, data: {} })}>
                            Add
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <Table head={['S.No', 'Name', 'EIC Code', 'Monitoring', 'Action']} keys={['s.no', 'name', 'EICCode', 'monitoring', 'action']} data={tableData} />
            </div>
        </div>
    );
};

export default EICCodes;
