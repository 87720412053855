import moment from 'moment';
import momentTz from 'moment-timezone';

export const formatDateString = (date) => {
    date = new Date(toCET(new Date(date)));
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const momentTimeFormater = (date) => {
    let momentDate = momentTz.tz(date, 'Europe/Berlin');
    return momentDate;
};

// used for converting utc and formate in to date
export const getTimeZoneOffset = (date) => {
    let momentDate = moment(date).format('YYYY-MM-DD');
    momentDate = momentTz.tz(momentDate, 'Europe/Berlin');
    momentDate = moment(momentDate).utcOffset() / 60;
    return momentDate;
};

export const formatDateStringMomentNew = (date) => {
    date = new Date(date);
    let month = ('0' + (date.getMonth() + 1)).slice(-2);
    let Datec = ('0' + date.getDate()).slice(-2);
    return `${date.getFullYear()}-${month}-${Datec}`;
};

export const formatDateStringMoment = (date) => {
    date = new Date(toCET(new Date(date)));
    let month = ('0' + (date.getMonth() + 1)).slice(-2);
    let Datec = ('0' + date.getDate()).slice(-2);
    return `${date.getFullYear()}-${month}-${Datec}`;
};

export const formatStartDateStringMoment = (date) => {
    date = moment(date).subtract(1, 'days').format('YYYY-MM-DD');
    return `${date}`;
};

export const formatEndDateStringMoment = (date) => {
    date = new Date(date);
    let month = (date.getMonth() + 1).toString().padStart(2, 0);
    let Datec = date.getDate().toString().padStart(2, 0);
    return `${date.getFullYear()}-${month}-${Datec}`;
};

export const toCET = (date) => {
    date = new Date(date);
    return date.toLocaleString('en-us', { timeZone: 'CET' });
};

export const getDuration = (start, end) => {
    const dur = moment.duration(moment(end).diff(moment(start)));
    return `${dur.minutes()} min, ${dur.seconds()} sec`;
};

export const timeStampGenerator24 = (date, array, hour = 24) => {
    for (let i = 0; i < hour; i++) {
        array.push(new Date(date.getTime() + i * 60 * 60 * 1000));
    }
    return array;
};

export const findDiffInSeconds = (start, end) => {
    const dif = moment(end).diff(moment(start), 'seconds');
    return dif;
};

export const formatDuration = (time, unit = 'minutes') => {
    const duration = moment.duration(time, unit);
    // Get the full days, hours, minutes, and seconds
    const days = Math.floor(Math.abs(duration.asDays()));
    const hours = Math.floor(duration.hours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const formattedSeconds = seconds < 0 ? `${seconds}` : seconds.toString().padStart(2, '0');
    return `${days.toString().padStart(2, '0')}D:` +
        `${hours.toString().padStart(2, '0')}H:` +
        `${minutes.toString().padStart(2, '0')}M:` +
        `${formattedSeconds}S`;
};
