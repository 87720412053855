import * as yup from 'yup';
import { camelCase } from 'lodash';
import { ERRORS } from '../../utils/validationHelper';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const CreateCustomerValidator = (signalMap, areas) => {
    const areaValidation = {};
    const validationMap = {};

    Object.keys(signalMap).forEach((type) => {
        const key = camelCase(type);
        const curValidation = {};
        signalMap[type].forEach((signal) => {
            curValidation[signal._id] = yup
                .boolean()
                .required()
                .test('area-test', `Area ${signal.area} should be enabled`, (value, context) => {
                    const areaContext = context.from[1]?.value?.areas || {};
                    if (value && areaContext[signal.area]) return true;
                    else if (!value) return true;
                    else return false;
                });
        });
        validationMap[key] = yup.object({ ...curValidation });
    });

    areas.map((item) => {
        areaValidation[item] = yup.boolean().required();
    });
    return yup.object({
        ...validationMap,
        areas: yup.object({
            ...areaValidation,
        }),
        companyName: yup.string().min(3).max(24).required(),
        dataMonitoring: yup.boolean(),
        address: yup.object({
            address1: yup.string().min(3).max(32),
            address2: yup.string().min(3).max(32),
            primaryContactName: yup.string().min(3).max(32),
            primaryContactEmail: yup.string().matches(emailRegex, 'invalid email'),
            billingEmail: yup.string().matches(emailRegex, 'invalid email'),
            // street: yup.string().min(3).max(30),
            zip: yup.string().min(4).max(5),
            city: yup.string().min(3).max(20),
            contact: yup.string().matches(phoneRegExp, 'invalid contact number'),
            countryCode: yup.string(),
            emergencyContact: yup.array().of(
                yup.object().shape({
                    name: yup.string().min(3).max(32).required(ERRORS.required),
                    email: yup.string().matches(emailRegex, 'invalid email').optional(),
                    contactNumber: yup.string().matches(phoneRegExp, 'invalid contact number').required(ERRORS.required),
                    countryCode: yup.string().required(ERRORS.required),
                })
            ),
            invoiceInfo: yup.array().of(
                yup.object().shape({
                    name: yup.string().optional(),
                    email: yup.string().matches(emailRegex, 'invalid email').optional(),
                })
            )
        }),
        name: yup.string().min(3).max(32).required(),
        vat: yup.string().required(),
        trueGreen: yup.object({
            enabled: yup.boolean(),
        }),
        theme: yup.object({
            primaryColor: yup.string().required(),
        }),
        timezone: yup.string().required(),
        locales: yup.string().required(),
        isActive: yup.boolean().required(),
        customerCode: yup.string().required(),
    });
};
