import React, { useEffect, useState } from 'react';
import { BiddingService } from '../../../../services/BiddingService';
// External library imports
import { toast } from 'react-toastify';
import moment from 'moment';
import _ from 'lodash';
import classes from '../../../../styles/Bids.module.css';
import { useLoader } from '../../../../hooks';
import Table from '../../../../components/Table/Table';
import Typography from '../../../../components/Typography/Typography';
import DatePicker from '../../../../components/Inputs/DatePicker/DatePicker';
import mainBiddingClasses from '../index.module.css';
import { DropdownComponent } from '../../../../components/Inputs/Input';
import { momentTimeFormater } from '../../../../utils/dateHelper';

const marketOption = [
    {
        label: 'FCR-D-INC/FCR-D-DEC',
        value: 'FCR-D',
    },
    {
        label: 'FFR',
        value: 'FFR',
    },
    {
        label: 'aFRR (Capacity)',
        value: 'aFRR-capacity',
    },
    {
        label: 'aFRR (Energy)',
        value: 'aFRR-energy',
    },
];

const getPostBidDeadLine = (date) => {
    return {
        currentCETTime: momentTimeFormater(momentTimeFormater().format('YYYY-MM-DD HH:mm')),
        primary: momentTimeFormater(momentTimeFormater(date).subtract(1, 'days').set({ hour: 0, minute: 30, second: 0, millisecond: 0 }).format('YYYY-MM-DD HH:mm')),
        secondary: momentTimeFormater(momentTimeFormater(date).subtract(1, 'days').set({ hour: 18, minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DD HH:mm')),
    };
};

export const PostBidToEnerginet = ({ type, bidDate, setOpen, fetchBids }) => {
    const [startLoader, stopLoader] = useLoader();
    const [date, setDate] = useState(moment(bidDate).toDate());
    const [bidsData, setBidsData] = useState({
        normalBids: [],
        bundleBids: [],
    });
    const [selectedMarket, setSelectedMarket] = useState(marketOption[0]);
    const [postBidDeadLine, setPostBidDeadLine] = useState(getPostBidDeadLine(date));

    useEffect(() => {
        setPostBidDeadLine(getPostBidDeadLine(date));
    }, [date]);

    useEffect(() => {
        fetchData();
    }, [selectedMarket, date]);

    const fetchData = () => {
        if (selectedMarket.value) {
            const params = {
                market: selectedMarket.value,
                bidDate: moment(date).format('YYYY-MM-DD'),
                type,
            };

            BiddingService.GetAllBidsForEnerginet(params, startLoader, handleBidsSuccess, handleError, stopLoader);
        }
    };

    const handleBidsSuccess = ({ data }) => {
        const normalBids = data?.data.normalBids?.map((item) => {
            return {
                customerName: item.customerId.name,
                instance: item?.instanceId.name,
                market: item?.market,
                averageCapacity: _.get(item, [type, 'capacity'], []).length ? _.mean(_.get(item, [type, 'capacity'])).toFixed(2) : '--',
            };
        });
        const bundleBids = data?.data.bundleBids?.map((item) => {
            return {
                customerName: item.customerId.name,
                instanceBundle: item?.instanceBundleId.name,
                market: item?.market,
                averageCapacity: _.get(item, [type, 'capacity'], []).length ? _.mean(_.get(item, [type, 'capacity'])).toFixed(2) : '--',
            };
        });
        setBidsData({ normalBids: normalBids, bundleBids: bundleBids });
    };

    const handleError = (err) => {
        if (err && !!err?.response?.data?.message) {
            toast.error(err.response.data.message);
        }
    };

    const handleSubmit = () => {
        if (!postBidDeadLine.currentCETTime.isAfter(_.get(postBidDeadLine, [type]))) {
            const payload = {
                bidDate: moment(date).format('YYYY-MM-DD'),
                market: selectedMarket.value,
                type,
            };
            BiddingService.PostBidEnerginet(payload, startLoader, handlePostSuccess, handleError, stopLoader);
        } else {
            toast.error('Can not post bid after deadline.');
        }
    };

    const handlePostSuccess = () => {
        toast.success('Bids Posted Successfuly');
        setOpen({ status: false, type: '' });
        fetchBids();
    };

    return (
        <div>
            <div className={mainBiddingClasses.MainModalContainer}>
                <div className={mainBiddingClasses.DateContainer}>
                    <Typography content={`Post Bids to Energinet (${selectedMarket?.label})`} />
                    <div style={{ display: 'flex', gap: '0.5vw' }}>
                        <div style={{ width: '10vw' }}>
                            <DropdownComponent defaultValue={selectedMarket} options={marketOption} onChange={setSelectedMarket} />
                        </div>
                        <div className="modal-date-picker" style={{ width: '8vw' }}>
                            <DatePicker
                                date={date}
                                setDate={(d) => {
                                    setDate(d);
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className={classes.Bids}>
                    <div className={mainBiddingClasses.PostBidModalHeading}>Normal bids</div>
                    <Table
                        head={['Customer Name', 'Instance', 'Market', 'Avg Capacity']}
                        keys={['customerName', 'instance', 'market', 'averageCapacity']}
                        data={bidsData.normalBids}
                    />
                </div>

                <div className={classes.Bids}>
                    <div className={mainBiddingClasses.PostBidModalHeading}>Bundle bids</div>
                    <Table
                        head={['Customer Name', 'Instance Bundle', 'Market', 'Avg Capacity']}
                        keys={['customerName', 'instanceBundle', 'market', 'averageCapacity']}
                        data={bidsData.bundleBids}
                    />
                </div>
                <div>
                    <div className={classes.ButtonContainer}>
                        <div onClick={() => setOpen({ status: false, type: '' })}>
                            <button type="button" className="btn-secondary">
                                Cancel
                            </button>
                        </div>
                        <div onClick={handleSubmit}>
                            <button type="submit" className="btn-primary">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
