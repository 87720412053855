import React, { useEffect, useState } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import classes from '../../../../styles/CreateDevice.module.css';
import Typography from '../../../../components/Typography/Typography';
import { Input, InputFile, ToggleButton } from '../../../../components/Inputs/Input';
import { useLoader } from '../../../../hooks';
import { toast } from 'react-toastify';
import { createEnabler, updateEnabler } from '../../../../validations/Root/enablerValidator';
import PhoneNumberComponent from '../../../../components/Inputs/PhoneNumberComponent';
import FileUpload from '../../../../components/Inputs/FileUpload/FileUpload';
import { EnablerService } from '../../../../services/EnablerService';
import MultiSelectDropdown from '../../../../components/Inputs/MultiSelectDropdown';
import { UilMinusCircle, UilPlusCircle } from '@iconscout/react-unicons';

const getInitialValues = (enablerData) => {
    if (enablerData?._id) {
        return {
            name: enablerData.name,
            email: enablerData.email,
            password: '',
            countryCode: enablerData.countryCode,
            contact: enablerData.contact,
            accessBlocked: enablerData.accessBlocked,
            enablerCustomerId: enablerData?.enablerCustomerId ? enablerData?.enablerCustomerId : [],
            address: {
                invoiceInfo: enablerData?.address?.invoiceInfo || [{
                    name: '',
                    email: ''
                }],
            }
        };
    } else {
        return {
            name: '',
            email: '',
            password: '',
            countryCode: '',
            contact: '',
            accessBlocked: false,
            enablerCustomerId: [],
            address: {
                invoiceInfo: [{
                    name: '',
                    email: ''
                }],
            }
        };
    }
};

const CreateEnabler = ({ enablerData }) => {
    const [startLoader, stopLoader] = useLoader();
    const [profileImage, setProfileImage] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [selectedCustomers, setSelectedCustomer] = useState([
        {
            label: '',
            value: '',
        },
    ]);
    const initialValues = getInitialValues(enablerData);

    //   useEffect(() => {
    //     console.log({ enablerData });
    //     if (enablerData?._id) {
    //       let temp = [];
    //       enablerData?.enablerCustomerId &&
    //         enablerData?.enablerCustomerId.map((item) => {
    //           temp.push({
    //             label: "",
    //             value: item,
    //           });
    //         });
    //       setSelectedCustomer(temp);
    //     }
    //   }, [enablerData]);

    useEffect(() => {
        getCustomers();
    }, []);

    const getCustomers = () => {
        EnablerService.GetCustomers(startLoader, handleGetCustomersSuccess, handleError, stopLoader);
    };

    const handleGetCustomersSuccess = ({ data }) => {
        let temp = [];
        data?.data?.map((item) => {
            temp.push({
                label: item.name,
                value: item._id,
            });
        });
        setCustomers(temp);
    };

    const handleSubmit = (values, resetForm) => {
        values = {
            ...values,
            address: {
                invoiceInfo: values?.address?.invoiceInfo.length > 0 ?
                    values?.address?.invoiceInfo.map((invoiceInfo) => {
                        return {
                            name: invoiceInfo.name ? invoiceInfo.name : undefined,
                            email: invoiceInfo.email ? invoiceInfo.email : undefined,
                        }
                    }) :
                    undefined
            }
        };
        const formData = new FormData();
        const isProfileImageValid = /(jpg|png|peg|svg)$/.test(profileImage?.name?.slice(-4));
        if (profileImage.length && !isProfileImageValid) {
            toast.error('Please select a valid image format');
            return;
        }
        formData.append('profileImage', profileImage);
        Object.keys(values).forEach((key) => {
            let value = values[key]
            if (key === 'address') value = JSON.stringify(values[key]);
            formData.append(key, value);
        });

        formData.delete('enablerCustomerId');

        values.enablerCustomerId.length &&
            values.enablerCustomerId.map((e) => {
                formData.append('enablerCustomerId[]', e.value);
            });

        if (enablerData?._id) {
            EnablerService.Update(enablerData._id, formData, startLoader, handleUpdateSuccess, handleError, stopLoader);
        } else {
            EnablerService.Create(formData, startLoader, ({ data }) => handleCreateSuccess(data, resetForm), handleError, stopLoader);
        }
    };

    const handleUpdateSuccess = () => {
        toast.success('Enabler updated!');
    };

    const handleCreateSuccess = (data, resetForm) => {
        resetForm();
        toast.success('Enabler created!');
    };

    const handleUploadClick = (e) => {
        const file = e.target.files[0];
        setProfileImage(file);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div className={classes.FormContainer}>
            <Typography content={`${enablerData?._id ? 'Edit' : 'Create'} Enabler`} />
            <div className={classes.FormContent}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(val, { resetForm }) => handleSubmit(val, resetForm)}
                    validationSchema={enablerData?._id ? updateEnabler : createEnabler}
                >
                    {({ errors, touched, values, isValidating, ...props }) => {
                        return (
                            <Form>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label for="name">
                                            Name <span className="required">*</span>
                                        </label>
                                        <Input name="name" id="name" />
                                    </div>
                                    <div className={classes.FieldControl}>
                                        <label for="Email">
                                            Email <span className="required">*</span>
                                        </label>
                                        <Input name="email" id="Email" />
                                    </div>
                                </div>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label for="password">
                                            Password <span className="required">*</span>
                                        </label>
                                        <Input name="password" id="password" type="password" />
                                    </div>
                                    <div className={classes.FieldControl}>
                                        <label for="contact">
                                            Contact <span className="required">*</span>
                                        </label>
                                        <div>
                                            <PhoneNumberComponent
                                                inputName="contact"
                                                countryCodeName="countryCode"
                                                inputValues={values}
                                                defaultCountryCode={{ value: values.countryCode }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <FieldArray name="address.invoiceInfo">
                                    {({ push, remove, form }) => {
                                        const invoiceInfo = form.values.address?.invoiceInfo;
                                        return (
                                            <div>
                                                <div>
                                                    <div className={classes.InputContainer}>
                                                        <div className={classes.FieldControl2}>
                                                            <div className={classes.Title}>Invoice</div>
                                                            {invoiceInfo.map((data, index) => (
                                                                <div className={classes.InputContainer}>
                                                                    <div className={classes.FieldControl}>
                                                                        <label for="name">Name</label>
                                                                        <Input name={`address.invoiceInfo[${index}].name`} id="name" type="text" />
                                                                    </div>
                                                                    <div className={classes.FieldControl}>
                                                                        <label for="email">Email </label>
                                                                        <Input
                                                                            name={`address.invoiceInfo[${index}].email`}
                                                                            id="email" type="text" />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={classes.addInputActionWrapper}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row-reverse',
                                                        gap: '0.5vw',
                                                        paddingTop: '1%',
                                                    }}
                                                >
                                                    <span
                                                        onClick={() => {
                                                            push('');
                                                        }}
                                                    >
                                                        <UilPlusCircle
                                                            size={'1.5vw'}
                                                            style={{
                                                                color: 'var(--color-primary)',
                                                                cursor: 'pointer',
                                                                zIndex: '1',
                                                            }}
                                                        />
                                                    </span>
                                                    {invoiceInfo.length > 1 && (
                                                        <span onClick={() => remove(invoiceInfo.length - 1)}>
                                                            <UilMinusCircle
                                                                size={'1.5vw'}
                                                                style={{
                                                                    color: 'var(--color-primary)',
                                                                    cursor: 'pointer',
                                                                    zIndex: '1',
                                                                }}
                                                            />
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    }}
                                </FieldArray>

                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label for="profileImage">Profile Image</label>
                                        <FileUpload name="profileImage" id="profileImage" accept="image/jpg, image/jpeg, image/png, image/svg+xml" onChange={handleUploadClick} />
                                    </div>
                                    <div className={classes.FieldControl}>
                                        <div>
                                            <label for="enablerCustomerId">Select Customers</label>
                                            {console.log({ customers })}
                                            {console.log(
                                                initialValues?.enablerCustomerId.map((e) => ({
                                                    value: e,
                                                }))
                                            )}
                                            <MultiSelectDropdown
                                                name="enablerCustomerId"
                                                options={customers}
                                                defaultValue={initialValues?.enablerCustomerId.map((e) => ({
                                                    value: e,
                                                }))}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <ToggleButton values={values} label={'Block Access'} name={`accessBlocked`} />
                                    </div>
                                </div>
                                <div className={classes.ButtonContainer}>
                                    <div>
                                        <button type="submit" className="btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default CreateEnabler;
